import { QueryResult, client } from '@/api';
import { queryCategoriesType } from './query';

export type Categories = QueryResult<ReturnType<typeof queryCategoriesType>>['categorie_type'][0];

export const categoriesActions = {
  get: (token: string, shopId: string): Promise<QueryResult<ReturnType<typeof queryCategoriesType>>> => {
    return client().query(queryCategoriesType(token, shopId));
  }
};
