import { QueryResult, client } from '@/api';
import { queryProducts, queryDiscountByDate, queryProductById } from './query';

export type Availability = {
  limit: boolean;
  dates: string[];
  weekDays: string[];
  hours: string[];
};

export type Article = QueryResult<ReturnType<typeof queryProducts>>['articles'][0];

export interface Product extends Article {
  availability: Availability[];
}

export type ProductOptions = Product['child'][0];
export type ProductDeclanation = Product['declinations'][0];
export type Medias = Product['medias'][0];

export type Discount = NonNullable<Product['discount']>;

export const productsActions = {
  get: (orgActId: string, shopId: string, start?: Date): Promise<QueryResult<ReturnType<typeof queryProducts>>> => {
    return client().query(queryProducts(orgActId, shopId, start));
  },
  getProduct: (id: string, date: Date): Promise<QueryResult<ReturnType<typeof queryProductById>>> => {
    return client().query(queryProductById(id, date));
  },
  getDiscount(articleId: string, dateStart: Date): Promise<QueryResult<ReturnType<typeof queryDiscountByDate>>> {
    return client().query(queryDiscountByDate(articleId, dateStart));
  }
};
