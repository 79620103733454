import { h, computed, reactive } from 'vue';
import { DialogOptions } from 'naive-ui';
import { useMatches } from 'vue-responsiveness';
import { HeaderModal, ScrollContent } from '@/components/global';

type DialogOpt = DialogOptions & { backIcon?: boolean };

export function openModal(data: DialogOpt, style = {}) {
  const matches = useMatches();

  const datainit = reactive(data);
  const dialog = window.$openDialog.create({
    scroll: false,
    transformOrigin: 'center',
    showIcon: false,
    autoFocus: false,
    maskClosable: false,
    closable: false,
    loading: false,
    style: {
      maxWidth: '1250px',
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: computed(() => (matches.isOnly('sm') ? '0px' : '30px')),
      padding: '0px',
      position: computed(() => (matches.isOnly('sm') ? 'fixed' : 'relative')),
      zIndex: 99,
      ...style
    },
    ...datainit,
    editData: (da: DialogOpt) => {
      Object.assign(datainit, da);
    },
    content: () =>
      h(ScrollContent, null, {
        default: ({ scrollTo }) => datainit.content({ scrollTo })
      }),
    title: () => {
      return h(HeaderModal, datainit);
    }
  });
  dialog.editData({ close: () => dialog.destroy() });
  return dialog;
}
