import { createApp, getCurrentInstance, h, reactive, watch } from 'vue';
import { initPlugins } from '@/plugins';
import { UserAccount, UserCaddie, MainCategories, MainProducts } from '@/layouts';
import { setupDirectives } from '@/directives';
import App from './App.vue';

const plugins = initPlugins();

const app = createApp(App);
setupDirectives(app);

Object.values(plugins).forEach(plugin => {
  app.use(plugin);
});

function parseObjectValues(obj: { [key: string]: string }) {
  const parsedObj: { [key: string]: any } = {};

  Object.keys(obj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      try {
        parsedObj[key] = JSON.parse(obj[key]);
      } catch (error) {
        // Si le parsing échoue, conservez la valeur originale
        parsedObj[key] = obj[key];
      }
    }
  });

  return parsedObj;
}

export function loadComponent(name: 'MainCategories' | 'MainProducts' | 'UserAccount' | 'UserCaddie', token: string) {
  const comps = {
    MainCategories,
    MainProducts,
    UserAccount,
    UserCaddie
  };

  return {
    setup() {
      interface Inst {
        appContext: any;
        provides: any;
        vnode: any;
      }

      const inst = getCurrentInstance();

      Object.assign(inst?.appContext, app._context);
      Object.assign(inst?.provides, app._context.provides);
      const reactiveProps = reactive(parseObjectValues(inst?.proxy.$attrs));

      watch(
        () => inst?.proxy.$attrs,
        newProps => {
          if (newProps) Object.assign(reactiveProps, parseObjectValues(newProps));
        },
        { deep: true }
      );

      return () => h(App, { token }, () => [h(comps[name], reactiveProps)]);
    }
  };
}
