<template>
  <div class="flex flex-col gap-6 bg-white rounded-xl p-6">
    <p class="text-lg font-bold mb-2">Mes réservations</p>
    <UserOrder v-for="order in orders" :key="order.id" :order="order" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue';
import { useOrdersStore } from '@/stores';
import { UserOrder } from './components';

const ordersStore = useOrdersStore();

const orders = computed(() => ordersStore.orders);

onMounted(async () => {
  await ordersStore.get();
});
</script>
