<template>
  <div
    class="rounded-xl bg-gray-1 box-border flex items-center justify-center"
    ref="container"
    :style="loading ? 'width: ' + width + 'px' : ''"
  >
    <n-spin v-if="loading" size="small" class="mx-5" />
    <div v-else class="flex gap-1 h-full items-center px-1">
      <div class="flex flex-col gap-1 items-end p-3">
        <span v-if="price.discount" class="text-nowrap text-sm line-through">{{ price.price }}</span>
        <span class="font-bold text-nowrap" :style="'color: ' + useColor().primary">{{ price.discountPrice }}</span>
      </div>
      <div
        v-if="price.discount"
        :style="'background-color: ' + useColor().primary"
        class="h-12 w-12 flex box-border text-nowrap text-sm font-bold p-2 text-white rounded-full items-center"
      >
        <span>{{ price.discount }}%</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch, computed, nextTick } from 'vue';
import { NSpin } from 'naive-ui';
import { useColor } from '@/composables';

const props = defineProps({
  price: null,
  loading: { type: Boolean }
});

const container = ref<HTMLElement | null>(null);
const width = ref<number>(0);

const isLoading = computed(() => props.loading);

watch(isLoading, value => {
  if (!value) {
    nextTick(() => {
      width.value = container.value?.offsetWidth || 0;
    });
  }
});

onMounted(() => {
  if (container.value) {
    width.value = container.value.offsetWidth;
  }
});
</script>
