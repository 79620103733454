import { MutationResult, client, QueryResult } from '@/api';
import { useOrganisationStore } from '@/stores';
import { mutationOrder, mutationPayment } from './mutation';
import { queryOrders } from './query';

type Orders = QueryResult<ReturnType<typeof queryOrders>>;
export type Order = Orders['orders'][0];

export const orderAction = {
  get(): Promise<Orders> {
    const orgId = useOrganisationStore().getOrgId;
    return client().query(queryOrders(orgId));
  },
  add: (): Promise<MutationResult<typeof mutationOrder>> => {
    return client().mutation(mutationOrder);
  },
  createPayment: (
    orderId: string,
    paymentMethodId: string
  ): Promise<MutationResult<ReturnType<typeof mutationPayment>>> => {
    return client().mutation(mutationPayment(orderId, paymentMethodId));
  }
};
