<template>
  <div v-if="rule" class="min-h-[500px]">
    <div class="flex gap-6">
      <div v-if="!$matches.sm.only" class="flex-1 bg-white p-4 rounded-xl">
        <auto-form
          v-model="formValue"
          :form-value="formValue"
          :rule="rule"
          class="flex-1 mt-6"
          @update:fapi="value => (fapi = value)"
        />
      </div>
      <div v-if="product" class="flex-1 flex flex-col justify-between">
        <div class="flex flex-col h-full gap-3">
          <div class="bg-white p-6 rounded-xl">
            <p class="mb-4 text-2xl">{{ product?.description.title }}</p>
            <div class="flex-1">
              <div class="flex justify-between mb-6 gap-6">
                <div class="py-3 text-md h-auto flex flex-col justify-center flex-1">
                  <div class="flex items-center gap-2 text-sm mb-2">
                    <icon-carbon:calendar />
                    <div>
                      Date :
                      <div v-if="product.stage_days" class="inline-block">
                        du <span v-date="props.dateStart" format="dddd DD/MM/YYYY" /> au
                        <span v-date="stageEnd" format="dddd DD/MM/YYYY" />
                      </div>
                      <span v-else v-date="props.dateStart" format="dddd DD/MM/YYYY" />
                    </div>
                  </div>
                  <product-attributes class="text-sm" :product="product" :start-date="props.dateStart" />
                </div>
                <div v-if="!$matches.sm.only" class="w-[190px]">
                  <image-carousel
                    :medias="product.medias.filter((_p, ind: number) => ind === 0)"
                    :alt-text="product.description.title"
                    :height="130"
                    :image-holder="false"
                  />
                </div>
              </div>
              <product-categories :categories="product?.article_categories" :product="product" class="mt-1" />
            </div>
          </div>
          <div v-if="$matches.sm.only" class="bg-white p-6 rounded-xl">
            <auto-form
              v-model="formValue"
              :form-value="formValue"
              :rule="rule"
              :options
              class="flex-1"
              @update:fapi="value => (fapi = value)"
            />
          </div>
          <div class="bg-white p-6 rounded-xl" :class="{ 'mb-28': $matches.isOnly('sm') }">
            <p class="mb-2 font-bold">Détail du prix</p>
            <n-table :bordered="false" class="text-sm">
              <article-option-price
                :title="product.description.title"
                :price="activityPrice"
                :discount="discount"
                :is-discount="true"
              />
              <article-option-price
                v-for="price in priceOptions"
                :key="price.id"
                :title="price.name"
                :price="price.price"
                :discount="discount"
                :is-discount="price.isDiscount"
              />
              <tr class="text-md">
                <td class="font-bold !border-0">Total</td>
                <td v-money="total" class="text-right font-bold whitespace-nowrap !border-0" />
              </tr>
            </n-table>
          </div>
          <div
            class="w-full"
            :class="{
              'mt-4 h-full flex justify-end items-end': !$matches.sm.only,
              'fixed bottom-0 p-4 box-border left-0 pt-14 bg-gradient-to-t from-white from-70% ...': $matches.sm.only
            }"
          >
            <n-button
              :loading="loading"
              icon-placement="right"
              size="large"
              type="primary"
              class="w-full"
              :class="{ 'max-w-[250px]': !$matches.sm.only }"
              @click="submitForm"
            >
              Valider
            </n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, toRaw, computed } from 'vue';
import { NTable, ScrollbarInst, DialogReactive } from 'naive-ui';
import { Rule, Api } from '@form-create/naive-ui';
import { uid } from 'uid';
import { ProductAttributes, ProductCategories } from '@/layouts';
import { AutoForm } from '@/components';
import { isBoolean, dayjs } from '@/utils';
import { Product, productsActions } from '@/services';
import { useProductsStore, useCartStore, Cart, useOrganisationStore, useConfigStore } from '@/stores';
import { ruleCart } from './formSchema';
import { ArticleOptionPrice } from './components';

const props = defineProps({
  productId: null,
  dateStart: null,
  back: { type: Function },
  close: { type: Function },
  modal: null,
  cart: null,
  isToCart: { type: Function },
  resize: { type: Function },
  scrollTo: null
});

const productsStore = useProductsStore();
const cartStore = useCartStore();
const organisationStore = useOrganisationStore();
const configStore = useConfigStore();
const product = ref<Product>();
const rule = ref<Rule[]>();
const fapi = ref<Api>();
const formValue = ref<any>(toRaw(props.cart?.cart) || {});
const discount = ref(0);
const loading = ref(false);

const orgActId = computed(() => configStore.getOrgActId);

const activityPrice = computed(() => {
  let priceList = product.value?.price?.data[0];

  if (formValue.value.quantity && product.value?.price?.variation === 'quantity')
    priceList = product.value?.price?.data[Number(formValue.value.quantity) - 1];

  return product.value?.price_manual !== null ? product.value?.price_manual : priceList;
});

if (props.modal) {
  props.modal.style.maxWidth = '1100px';
  props.modal.editData({
    close: () => props.back(),
    backIcon: false
  });
}

const options = computed(() => {
  const productOptions: {
    title: string;
    id: string;
    isDiscount: boolean;
    price: any;
    manualPrice: number | null;
  }[] = [];

  product.value?.child.forEach(option => {
    productOptions.push({
      title: option.article.description.title,
      id: option.article.id,
      price: option.article.price,
      manualPrice: option.article.price_manual,
      isDiscount: option.is_discount
    });
    if (option.article.child.length > 0) {
      option.article.child.forEach(subOption => {
        productOptions.push({
          title: `${option.article.description.title} : ${subOption.article.description.title}`,
          id: subOption.article.id,
          price: subOption.article.price,
          manualPrice: subOption.article.price_manual,
          isDiscount: subOption.is_discount
        });
      });
    }
  });

  return productOptions.map(a => {
    let priceList: number = a.price?.data[0];

    if (formValue.value.quantity && a.price?.variation === 'quantity')
      priceList = a.price?.data[Number(formValue.value.quantity) - 1];

    return {
      name: a.title,
      id: a.id,
      price: a.manualPrice !== null ? a.manualPrice : priceList,
      isDiscount: a.isDiscount
    };
  });
});

const stageEnd = computed(() =>
  product.value?.stage_days ? dayjs(props.dateStart).add(Number(product.value.stage_days) - 1, 'day') : undefined
);

const priceOptions = computed(() => {
  const FlatFormValue = toRaw(formValue.value);
  return Object.keys(FlatFormValue)
    .filter(k => FlatFormValue[k])
    .map(k => (isBoolean(FlatFormValue[k]) ? k : FlatFormValue[k]))
    .map(k => options.value?.find(o => o.id === k))
    .filter(o => o);
});

function getDiscountPrice(price: number, discountValue: number, isDiscount = true) {
  if (discountValue && isDiscount) return price - (price * discountValue) / 100;
  return price;
}

const total = computed(() => {
  const optionsPrice = priceOptions.value.reduce(
    (acc, o) => (o ? acc + getDiscountPrice(o.price, discount.value, o.isDiscount) : 0),
    0
  );
  const totalP = getDiscountPrice(activityPrice.value, discount.value) + optionsPrice;
  return totalP;
});

const dateEnd = computed(() => {
  const [hour, minute] = product.value?.duration ? product.value.duration.split(':') : [0, 0];
  return dayjs(props.dateStart).add(Number(hour), 'hour').add(Number(minute), 'minute');
});

async function submitForm() {
  loading.value = true;
  try {
    const form = await fapi.value?.submit();

    if (props.cart?.cart) {
      cartStore.update(
        {
          ...props.cart,
          cart: form,
          dateStart: dayjs(props.dateStart).toDate(),
          dateEnd: dateEnd.value.toDate(),
          productId: props.productId,
          human: {
            article: product.value?.description.title,
            date: props.dateStart,
            price: total.value
          }
        },
        orgActId.value
      );
    } else {
      cartStore.add(
        {
          cart: form,
          dateStart: dayjs(props.dateStart).toDate(),
          dateEnd: dateEnd.value.toDate(),
          productId: props.productId,
          shopId: organisationStore.selectedShop.id,
          human: {
            article: product.value?.description.title,
            date: props.dateStart,
            price: total.value
          },
          id: uid()
        },
        orgActId.value
      );
    }

    if (props.resize) props.resize('600px');
    if (props.isToCart) {
      props.isToCart(true);
    } else {
      props.close();
    }
  } catch (e) {
    loading.value = false;
    // console.log(e);
  }
}

onMounted(async () => {
  props.scrollTo?.({ top: 0 });

  product.value = await productsStore.getProductById(props.productId, props.dateStart);

  if (props.dateStart && product.value) {
    rule.value = ruleCart(toRaw(product.value), props.dateStart);
    const { articles } = await productsActions.getDiscount(props.productId, props.dateStart);
    if (articles[0]?.discount) {
      discount.value = articles[0].discount?.discount || articles[0].discount?.discount_dates[0].discount;
    }
  }
});
</script>
