<template>
  <n-button
    class="w-full justify-between px-3 h-full rounded-full min-w[160px] transition-none"
    :quaternary="!focused"
    icon-placement="right"
    :type="focused ? 'primary' : 'default'"
  >
    <div class="flex w-full flex-col p-3 items-start">
      <div class="font-bold text-sm mb-1 opacity-90">{{ select?.title.title }}</div>
      <div :class="{ 'opacity-70': !selectOption, 'font-bold': selectOption }">
        {{ selectOption ? selectOption.title.title : 'Sélectionner' }}
      </div>
    </div>

    <template #icon>
      <n-button
        v-if="selectOption"
        class="transition-none"
        :quaternary="!focused"
        :type="focused ? 'primary' : 'default'"
        circle
        @click="deleteSelection"
        ><icon-carbon:close
      /></n-button>
      <div v-else class="w-[34px]"></div>
    </template>
  </n-button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useCategoriesStore } from '@/stores';

const props = defineProps({
  modelValue: null,
  catetegorieId: null,
  focused: { type: Boolean }
});

const emit = defineEmits(["update:modelValue"]);

const categoriesStore = useCategoriesStore();

const select = computed(() => {
  return categoriesStore.categories.find(category => category.id === props.catetegorieId);
});

const selectOption = computed(() => {
  return props.modelValue[0] ? select.value?.categories?.find(cat => cat.id === props.modelValue[0]) : undefined;
});

function deleteSelection(e: MouseEvent) {
  e.stopPropagation();
  emit('update:modelValue', []);
}
</script>
<style scoped>
.focused-menu-btn:hover {
  background-color: var(--n-color);
}
</style>
```
