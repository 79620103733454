import { useQuery } from '@/api';

export function queryOrders(orgId: string) {
  return useQuery({
    orders: {
      __args: {
        where: {
          orders_orgs: {
            org_id: {
              _eq: orgId
            }
          }
        }
      },
      id: true,
      status: true,
      created_at: true,
      orders_orgs: {
        org_id: true,
        cart: true,
        total_discount: true,
        total: true,
        total_ht: true,
        tax_0: true,
        tax_55: true,
        tax_100: true,
        tax_200: true,
        taxes: true,
        number: true,
        currency: true,
        id: true,
        detail: true,
        status: true
      }
    }
  });
}
