<template>
  <div class="flex w-full gap-2 items-start justify-between">
    <div v-if="title" class="p-7 pb-2 text-xl">{{ title }}</div>
    <n-button circle class="m-2 backdrop-blur-sm bg-gray/40" size="large" quaternary :class="styleClass" @click="close">
      <icon-carbon:arrow-left v-if="backIcon" class="text-xl text-white" />
      <icon-carbon:close v-else class="text-xl text-black" />
    </n-button>
  </div>
</template>

<script setup lang="ts">
import { VNodeChild, computed } from 'vue';

const props = defineProps({
  title: { type: Function },
  close: { type: Function },
  backIcon: { type: Boolean }
});

const styleClass = computed(() => {
  if (props.backIcon) {
    return {
      'absolute z-20 top-3 left-3': props.backIcon
    };
  }
  return !props.title ? 'absolute z-20 top-3 right-3' : '';
});
</script>
