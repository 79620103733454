<template>
  <n-tabs type="line">
    <n-tab-pane
      v-for="categorie in productCategories"
      :key="categorie.id"
      :name="categorie.id"
      :tab="categorie.title.title"
    >
      <n-tabs v-if="categorie.categories.length > 1" type="line" size="small">
        <n-tab-pane v-for="cat in categorie.categories" :key="cat.id" :name="cat.id" :tab="cat.title.title">
          <product-tab :categorie="cat" />
        </n-tab-pane>
      </n-tabs>
      <product-tab v-else :categorie="categorie.categories[0]" />
    </n-tab-pane>
  </n-tabs>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { NTabs, NTabPane } from 'naive-ui';
import { Product } from '@/services';
import { useCategoriesStore } from '@/stores';
import { ProductTab } from '.';

const props = defineProps({
  product: null
});

const categoriesStore = useCategoriesStore();

const productCategories = computed(() => {
  return categoriesStore.categories
    .map(category => ({
      ...category,
      categories: category.categories.filter(categorie =>
        props.product.article_categories.some(ac => ac.categorie_id === categorie.id)
      )
    }))
    .filter(category => category.categories.length > 0);
});
</script>
