import { getLocalDate } from '@/utils';
import { useOrganisationStore } from '@/stores';

export function dateTimeChecker(date: Date) {
  const organisationStore = useOrganisationStore();

  const { params } = organisationStore.settings[0];

  const now = getLocalDate();

  if (!params.limitReservation) return true;
  const [hour, minute] = params.limitReservation.time.split(':');
  const limitDate = getLocalDate(date).subtract(params.limitReservation.day, 'd').set('h', hour).set('m', minute);
  return now.isBefore(limitDate);
}
