import { useQuery } from '@/api';

export const queryCategoriesType = (token: string, shopId: string) =>
  useQuery({
    categorie_type: {
      __args: {
        where: {
          organisation_activity: {
            tokens: { token: { _eq: token } }
          },
          categories: { article_categories: { article: { shops: { shop_org_act_id: { _eq: shopId } } } } }
        },
        order_by: [{ position: 'asc' }]
      },
      title: {
        __args: { path: 'fr' }
      },
      mode: true,
      id: true,
      categories: {
        __args: {
          where: { article_categories: { article: { shops: { shop_org_act_id: { _eq: shopId } } } } },
          order_by: [{ position: 'asc' }]
        },
        article_categories_aggregate: {
          aggregate: {
            count: true
          }
        },
        medias: {
          file: {
            id: true,
            updatedAt: true
          }
        },
        title: {
          __args: { path: 'fr' }
        },
        id: true
      }
    }
  });
