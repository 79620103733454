<template>
  <template v-if="!$matches.sm.only">
    <n-popover
      placement="bottom"
      :show="showPopover"
      trigger="click"
      :overlap="true"
      class="rounded-2xl overflow-hidden bg-white"
      @clickoutside="showPopover = false"
    >
      <template #trigger>
        <slot :date="modelValue" :toggle-calendar="toggleCalendar">
          <n-input :value="dateValue" readonly="true" @click="toggleCalendar">
            <template #prefix><icon-carbon:calendar class="mr-1" /></template>
          </n-input>
        </slot>
      </template>
      <n-card :bordered="false" class="w-[400px]">
        <r-calendar v-model="modelValue" v-bind="calendarProps" @date-select="showPopover = false" />
      </n-card>
    </n-popover>
  </template>
  <template v-else>
    <slot :date="modelValue" :toggle-calendar="toggleCalendar">
      <n-input :value="dateValue" readonly="true" @click="toggleCalendar">
        <template #prefix><icon-carbon:calendar :font-size="5" /></template>
      </n-input>
    </slot>
    <transition>
      <div v-if="showPopover" class="fixed top-0 left-0 w-full h-full bg-gray/50 z-9"></div>
    </transition>
    <transition name="fade">
      <div v-if="showPopover" class="fixed bottom-0 left-0 z-10 w-full shadow-2xl">
        <n-card v-click-outside="() => (showPopover = false)" :bordered="false" class="w-full">
          <r-calendar v-model="modelValue" v-bind="calendarProps" @date-select="showPopover = false" />
        </n-card>
      </div>
    </transition>
  </template>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, toRaw } from 'vue';
import { NPopover } from 'naive-ui';
import { isArray, dayjs } from '@/utils';
import { RCalendar, CalendarProps } from '@/components/common';

const props = defineProps({
  startDay: null,
  endDay: null,
  monthNb: null,
  model: null,
  format: { default: 'DD/MM/YYYY' }
});

const modelValue = defineModel<Date | [Date, Date | undefined]>();

const calendarProps = computed(() => {
  const propsCal = toRaw(props);
  return propsCal;
});

const dateValue = computed(() =>
  isArray(modelValue.value)
    ? dayjs(modelValue.value[0]).format(props.format)
    : dayjs(modelValue.value).format(props.format)
);
const showPopover = ref(false);
const dayNow = dayjs().startOf('day');

function toggleCalendar() {
  showPopover.value = !showPopover.value;
}

onMounted(() => {
  let initDay = modelValue.value ? dayjs(modelValue.value).startOf('day') : dayNow;

  if (props.startDay && initDay.isBefore(dayjs(props.startDay).startOf('day'))) {
    initDay = dayjs(props.startDay).startOf('day');
  }

  if (props.endDay && initDay.isAfter(dayjs(props.endDay).startOf('day'))) {
    initDay = dayjs(props.endDay).startOf('day');
  }
  modelValue.value = initDay.toDate();
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.1s,
    transform 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0%);
}
</style>
