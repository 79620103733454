<template>
  <n-input-group>
    <n-select
      :value="prefix"
      :consistent-menu-width="false"
      class="w-[80px]"
      :options="phoneCountry"
      :size="size"
      :render-label="renderLabel"
      :render-tag="renderTag"
      @update:value="v => onUpdate('prefix', v)"
    />
    <n-input
      :value="input"
      clearable
      type="text"
      :size="size"
      class="w-full"
      placeholder=""
      :show-button="false"
      @update:value="v => onUpdate('number', v)"
    />
  </n-input-group>
</template>

<script setup lang="ts">
import { h, computed } from 'vue';
import { SelectRenderLabel, SelectRenderTag } from 'naive-ui';
import { phoneCountry } from '@rentool/client-api';

const props = defineProps({
  modelValue: { default: ' ' },
  size: { default: 'large' }
});

const emit = defineEmits(["update:modelValue"]);

const prefix = computed(() => props.modelValue?.split(' ')[0] || '+33');
const input = computed<string | null>(() => props.modelValue?.split(' ')[1]) || null;

function getCdnImgFlag(pref: string) {
  return h('img', {
    src: `https://cdn.jsdelivr.net/npm/flag-icons/flags/4x3/${pref}.svg`,
    alt: pref,
    class: `mr-2 h-5 w-5 align-bottom`
  });
}

const renderLabel: SelectRenderLabel = option => {
  return [getCdnImgFlag(option.prefix as string), option.label as string];
};

const renderTag: SelectRenderTag = ({ option }) => {
  return [getCdnImgFlag(option.prefix as string)];
};

function onUpdate(field: 'prefix' | 'number', v: string | null) {
  const pref = field === 'prefix' ? v : prefix.value;
  const numb = field === 'number' ? v?.replace(/[^0-9]/g, '') : input.value;
  emit('update:modelValue', `${pref} ${numb}`);
}
</script>
