import { defineStore } from 'pinia';
import { orderAction, Order } from '@/services';

interface OrdersState {
  /** Liste des événements */
  orders: Order[];
}

const { get } = orderAction;

export const useOrdersStore = defineStore('orders-store', {
  state: (): OrdersState => {
    return {
      orders: []
    };
  },
  actions: {
    async get() {
      const { orders } = await get();
      this.orders = orders;
    },

    clear() {
      this.orders = [];
    }
  }
});
