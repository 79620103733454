import { useQuery } from '@/api';

const queryArticle = useQuery({
  articles: {
    id: true,
    description: { __args: { path: 'fr' } },
    duration: true,
    price_manual: true,
    stock: true,
    stage_days: true,
    availability: true,
    halfday: true,
    declination: { __args: { path: 'fr' } },
    declinations: {
      __args: { order_by: [{ position: 'asc' }] },
      id: true,
      title: { __args: { path: 'fr' } }
    },
    medias: {
      file: {
        id: true,
        updatedAt: true
      }
    },
    price: {
      data: true,
      variation: true,
      label: { __args: { path: 'fr' } }
    },
    bodytpl: {
      age: true,
      height: true,
      weight: true,
      shoeSize: true,
      headMeasure: true
    },
    condition: true,
    weekDays: true,
    child: {
      is_checked: true,
      is_required: true,
      is_discount: true,
      article: {
        id: true,
        description: { __args: { path: 'fr' } },
        price_manual: true,
        price: {
          data: true,
          variation: true
        },
        condition: true,
        weekDays: true,
        medias: {
          file: {
            id: true,
            updatedAt: true
          }
        },
        child: {
          is_checked: true,
          is_required: true,
          is_discount: true,
          article: {
            id: true,
            description: { __args: { path: 'fr' } },
            price_manual: true,
            price: {
              data: true,
              variation: true
            },
            condition: true,
            weekDays: true,
            medias: {
              file: {
                id: true,
                updatedAt: true
              }
            }
          }
        }
      }
    },
    article_categories: {
      __args: { order_by: [{ category: { categorie_type: { position: 'asc' } } }] },
      categorie_id: true
    }
  }
});

export const queryProducts = (token: string, shopId: string, date?: Date) => {
  const query = {
    articles: {
      ...queryArticle.articles,
      discount: {
        discount: true
      }
    }
  };

  if (date) {
    query.articles.discount.discount_dates = {
      __args: { where: { start: { _lte: date }, end: { _gte: date } } },
      discount: true
    };
  }

  return useQuery({
    articles: {
      __args: {
        where: {
          shops: { shop_org_act_id: { _eq: shopId } },
          organisation_activity: { tokens: { token: { _eq: token } } },
          ispack: { _eq: true }
        },
        order_by: [{ position: 'asc' }]
      },
      ...query.articles
    }
  });
};

export const queryProductById = (id: string, date: Date) => {
  return useQuery({
    articles_by_pk: {
      __args: { id },
      ...queryArticle.articles,
      discount: {
        discount: true,
        discount_dates: {
          __args: { where: { start: { _lte: date }, end: { _gte: date } } },
          discount: true
        }
      }
    }
  });
};

export function queryDiscountByDate(articleId: string, date: Date) {
  return useQuery({
    articles: {
      __args: { where: { id: { _eq: articleId } } },
      id: true,
      discount: {
        discount: true,
        discount_dates: {
          __args: { where: { start: { _lte: date }, end: { _gte: date } } },
          discount: true
        }
      }
    }
  });
}
