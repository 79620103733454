<script lang="ts">
const iconSize = 17
const iconColor = '#00000093'
</script>
<template>
  <template v-if="$matches.sm.only">
    <n-button
      v-if="userStore.user?.accessToken"
      round
      class="h-[44px] w-[44px] p-0 px-2"
      @click="() => displayUserGlobal('orders')"
    >
      <!-- <img v-if="user?.avatarUrl" :src="user?.avatarUrl" class="w-[33px] h-[33px] rounded-full" /> -->
      <icon-carbon:user-avatar-filled-alt class="text-[38px]" />
    </n-button>
    <n-button v-else round class="h-[44px] w-[44px] p-0" @click="login">
      <icon-carbon:user-avatar-filled-alt class="text-[30px]" />
    </n-button>
  </template>
  <template v-else>
    <n-dropdown v-if="userStore.user?.accessToken" :options="options" size="large" @select="onSelect">
      <n-button round class="h-[44px] w-[44px] w-auto p-1 px-2">
        <!-- <img v-if="user?.avatarUrl" :src="user?.avatarUrl" class="w-[33px] h-[33px] rounded-full" /> -->
        <icon-carbon:user-avatar-filled-alt class="text-[33px]" />
        <span v-if="userStore.profile?.first_name" class="mx-2">{{ userStore.profile?.first_name }}</span>
        <span v-else class="mx-2 min-w-12"><n-spin size="small" /></span>
      </n-button>
    </n-dropdown>
    <n-button v-else round class="h-auto p-2" @click="login">
      <div class="flex items-center">
        <icon-carbon:user-avatar-filled-alt class="text-[1.6rem]" />
        <span class="mx-2">Se connecter</span>
      </div>
    </n-button>
  </template>
</template>

<script setup lang="ts">
import { onMounted, h, computed } from 'vue';
import { NDropdown, DropdownOption, NSpin } from 'naive-ui';
import { openModal, iconifyRender } from '@/utils';
import { useUserStore } from '@/stores';
import { displayUserGlobal } from '../utils';
import { UserLoginRegister } from './components';

defineProps({
  primary: null
});

const userStore = useUserStore();




const options = computed<DropdownOption[]>(() => [
  {
    label: 'Mes réservations',
    key: 'orders',
    icon: iconifyRender('carbon:order-details', iconColor, iconSize)
  },
  {
    label: 'Mes informations',
    key: 'account',
    icon: iconifyRender('carbon:user-avatar', iconColor, iconSize)
  },
  {
    label: 'Déconnexion',
    key: 'logout',
    icon: iconifyRender('carbon:logout', iconColor, iconSize)
  }
]);

function login() {
  const modal = openModal(
    {
      content: () => h(UserLoginRegister, { modal, style: { padding: '10PX 30px 30px 30px' } }),
      title: 'Compte utilisateur'
    },
    {
      maxWidth: '900px'
    }
  );
}

const user = computed(() => userStore.user?.user);

function onSelect(key: string) {
  switch (key) {
    case 'account':
      displayUserGlobal('informations');
      break;
    case 'orders':
      displayUserGlobal('orders');
      break;
    case 'logout':
      userStore.logout();
      break;
    case 'login':
      login();
      break;
    case 'register':
      console.log('register');
      break;
    default:
      break;
  }
}

onMounted(() => {
  userStore.getSession();
});
</script>
./UserAccount/components
