<template>
  <n-scrollbar ref="scrollbar" :style="`maxHeight: ${height - 8}px`">
    <slot v-if="load" :scroll-to="load" />
  </n-scrollbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { NScrollbar, ScrollbarInst } from 'naive-ui';
import { useWindowSize } from '@vueuse/core';

const { height } = useWindowSize();
const load = ref<ScrollbarInst['scrollTo']>();
const scrollbar = ref<ScrollbarInst>();

onMounted(() => {
  load.value = scrollbar.value?.scrollTo;
});
</script>
