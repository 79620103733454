export function getToken() {
  const scriptElements = Array.from(document.getElementsByTagName('script')) as HTMLScriptElement[];

  if (scriptElements.length === 0) {
    return null;
  }

  for (let i = 0; i < scriptElements.length; i++) {
    const scriptElement = scriptElements[i];
    const scriptName = process.env.NODE_ENV === 'production' ? 'rentool.js' : 'main.ts';
    if (scriptElement.src.includes(scriptName)) {
      // search token param in scriptElement.src
      const url = new URL(scriptElement.src);
      const token = url.searchParams.get('token');
      // console.log('token', token);
      return token;
    }
  }
  return null;
}
