<template>
  <div class="flex items-center gap-2">
    <div class="flex flex-wrap gap-2 w-full items-center">
      <div class="w-full flex justify-center rounded-xl bg-gray-1 p-4" v-if="!productEvents.length">
        Pas de créneaux disponibles pour cette date
      </div>
      <n-button
        v-for="event in productEvents"
        :key="event.dateStart.toDateString"
        :disabled="!event.enabled.value"
        class="min-h-18 min-w-[120px] h-auto p-2 flex-1 rounded-xl text-sm justify-center items-center"
        :color="event.enabled.value ? '#13C572' : '#636363'"
        @click="$emit('update:eventDate', event.dateStart)"
      >
        <div class="flex flex-col w-full">
          <div v-if="event.stageEnd" class="text-wrap">
            <span v-date="event.dateStart" class="capitalize text-nowrap" format="ddd D MMMM" />
            -
            <span v-date="event.stageEnd" class="text-nowrap" format="ddd D MMMM" />
          </div>
          <div v-else v-date="event.dateStart" class="capitalize" format="ddd D MMMM" />
          <div class="mt-2">
            <span v-date="event.dateStart" format="H:mm" /> -
            <span v-date="event.dateEnd" format="H:mm" />
          </div>
        </div>
      </n-button>
    </div>
    <n-button v-if="productEvents.length" circle secondary @click="nexEvents">
      <template #icon><icon-carbon:chevron-right /> </template
    ></n-button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';
import { dateTimeChecker } from '@/composables';
import { dayjs, getLocalDate } from '@/utils';
import { Product, EventStockActions, Availability } from '@/services';
import { useOrganisationStore, useCartStore } from '@/stores';

const props = defineProps({
  product: null
});

defineEmits(["update:eventDate"]);

const model = defineModel<Date>();

const organisationStore = useOrganisationStore();
const cartStore = useCartStore();
const animation = ref<'prev' | 'next'>('next');

const openDates = organisationStore.getShopDates;

function checkLimitStartDay(availability: Availability[], startDate: dayjs.Dayjs) {
  let limitStartDay = startDate;

  const isInLimit = availability.some(ava => {
    if (ava.limit) {
      const [startDay, endDay] = ava.dates;

      // Check if startDate if between startDay and endDay
      if (
        startDate.isBetween(
          getLocalDate(startDay, 'DD/MM/YYYY').startOf('day'),
          getLocalDate(endDay, 'DD/MM/YYYY').endOf('day')
        )
      ) {
        return true;
      }
      return false;
    }
  });

  // if isInLimit is false, then we need to find the next limit start day after startDate
  if (!isInLimit) {
    availability.forEach(ava => {
      if (ava.limit) {
        const [startDay, _endDay] = ava.dates;
        const start = getLocalDate(startDay, 'DD/MM/YYYY').startOf('day');

        if (start.isAfter(startDate) && start.isBefore(limitStartDay)) {
          limitStartDay = start;
        }
      }
    });
  }

  return limitStartDay;
}

function getAvailability(availability: Availability[], startDate: dayjs.Dayjs): Availability | undefined {
  const ava = availability.find(ava => {
    if (!ava.limit) return true;
    const [startDay, endDay] = ava.dates;

    if (
      startDate.isBetween(
        getLocalDate(startDay, 'DD/MM/YYYY').startOf('day'),
        getLocalDate(endDay, 'DD/MM/YYYY').endOf('day'),
        'day',
        '[]'
      )
    ) {
      return true;
    }
    return false;
  });
  return ava;
}

const productEvents = computed(() => {
  if (model.value === null) return [];
  if (!props.product) return [];
  if (openDates.length === 0) return [];
  if (!props.product.availability) return [];

  let startDate = getLocalDate(model.value);

  const now = getLocalDate();

  if (startDate.isBefore(now)) {
    startDate = now;
  }

  if (startDate.isBefore(getLocalDate(openDates[0].dates[0]))) {
    startDate = getLocalDate(openDates[0].dates[0]).startOf('day');
  }

  startDate = checkLimitStartDay(props.product.availability, startDate);

  const events: {
    dateStart: Date;
    dateEnd: Date;
    duration: number;
    color: string;
    stageEnd?: Date;
    stock: Ref<number>;
    loading: boolean;
    enabled: Ref<boolean>;
  }[] = [];

  if (startDate.isAfter(getLocalDate(openDates[0].dates[1]))) {
    return [];
  }

  const eventsNumber = 4;
  let index = 0;

  while (events.length < eventsNumber && index < 70) {
    const date = startDate.add(index, 'day');
    const availability = getAvailability(props.product.availability, date);

    if (availability && availability.hours && availability.weekDays[date.weekday()]) {
      availability.hours.forEach(async hour => {
        const hourMinutes = hour.split(':');
        if (events.length === eventsNumber) return;

        const duration = props.product.duration?.split(':') || [];
        const dateStart = date.startOf('day').set('h', Number(hourMinutes[0])).set('m', Number(hourMinutes[1]));
        const stageEnd = props.product.stage_days
          ? dateStart.add(Number(props.product.stage_days) - 1, 'd').toDate()
          : undefined;

        if (dateStart.isBefore(startDate)) {
          return;
        }

        events.push({
          dateStart: dateStart.toDate(),
          dateEnd: dateStart.add(Number(duration[0]), 'h').add(Number(duration[1]), 'm').toDate(),
          duration: Number(props.product.duration),
          color: 'green',
          stageEnd,
          stock: ref(0),
          enabled: ref(false),
          loading: true
        });
      });
    }
    index++;
  }

  events.forEach(event => {
    if (!props.product.stock) {
      event.enabled.value = false;
      event.loading = false;
      return;
    }
    EventStockActions.get(props.product.id, event.dateStart, event.dateEnd).then(
      ({ orders_products_aggregate: stock, articles_by_pk: article }) => {
        let stocktTotal = 0;
        let stockQuantity = 1;
        const stockArticle: string[] = [];

        if (article && article?.article_stocks.length > 0) {
          stockQuantity = article.article_stocks[0]?.stock.quantity;
          stocktTotal = article?.article_stocks[0]?.stock.article_stocks.reduce((acc, art) => {
            if (art.article?.orders_products_aggregate?.aggregate?.count) stockArticle.push(art.article_id);
            let totalEv = art.article?.orders_products_aggregate?.aggregate?.count || 0;
            cartStore.cart.forEach(cart => {
              if (
                totalEv === 0 &&
                cart.productId === art.article_id &&
                (getLocalDate(cart.dateStart).isBetween(dayjs(event.dateStart), dayjs(event.dateEnd), null, '[]') ||
                  dayjs(event.dateStart).isBetween(
                    getLocalDate(cart.dateStart),
                    getLocalDate(cart.dateEnd),
                    null,
                    '[]'
                  ))
              ) {
                totalEv = 1;
                if (!stockArticle.includes(art.article_id)) stockArticle.push(art.article_id);
              }
            });
            return acc + totalEv;
          }, 0);
          // find in cart
        }

        let eventStock = stock.aggregate ? stock.aggregate.count : 0;

        cartStore.cart.forEach(cart => {
          if (cart.productId === props.product.id && getLocalDate(cart.dateStart).isSame(dayjs(event.dateStart))) {
            eventStock += 1;
          }
        });

        event.stock.value = props.product.stock - eventStock;

        if (!dateTimeChecker(event.dateStart)) {
          event.enabled.value = false;
          event.loading = false;
          return;
        }

        if (stockQuantity === 0) {
          event.enabled.value = false;
          event.loading = false;
          return;
        }

        if (eventStock >= props.product.stock) {
          event.enabled.value = false;
          event.loading = false;
          return;
        }

        if (stocktTotal >= stockQuantity) {
          event.enabled.value = !!stockArticle.includes(props.product.id);
          event.loading = false;
          return;
        }

        event.enabled.value = true;
        event.loading = false;
      }
    );
  });

  return events;
});

function nexEvents() {
  animation.value = 'next';
  if (!productEvents.value.length) return;

  model.value = getLocalDate(productEvents.value[0].dateEnd).toDate();
}

function prevEvents() {
  animation.value = 'prev';
  if (!productEvents.value.length) return;
  model.value = getLocalDate(productEvents.value[0].dateEnd).toDate();
}
</script>
<style>
.list-next-enter-active,
.list-prev-enter-active,
.list-next-leave-from,
.list-prev-leave-from,
.list-next-leave-to,
.list-prev-leave-to {
  transition: all 0.3s ease;
}

.list-next-enter-from {
  transform: translateX(calc(100% + 10px));
  z-index: 2;
}

.list-next-enter-to {
  z-index: 2;
}

.list-next-leave-from {
  position: absolute;
  z-index: 1;
}

.list-next-leave-to {
  z-index: 1;
  position: absolute;
  transform: scale(0);
}

.list-prev-enter-from {
  transform: translateX(calc(-100% - 10px));
  z-index: 2;
}

.list-prev-enter-to {
  z-index: 2;
}

.list-prev-leave-from {
  position: absolute;
  z-index: 1;
  right: 0;
}

.list-prev-leave-to {
  z-index: 1;
  right: 0;
  transform: scale(0);
  position: absolute;
}

.event-leave-active {
  transition: opacity 0.3s ease;
  position: absolute;
  z-index: 2;
}

.event-leave-to {
  opacity: 0;
}
</style>
