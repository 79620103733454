<template>
  <div>
    <auto-form v-model="loginModel" :rule="registerRules" @update:fapi="value => (fapi = value)" />
    <div v-if="errorMsg" class="flex justify-end text-red-700 mb-4">
      {{ errorMsg }}
    </div>
    <div class="opacity-70 py-3">
      En créant un compte, vous acceptez les
      <n-button text href="/cgu" target="_blank" class="text-primary">Conditions Générales d'Utilisation</n-button> et
      la <n-button text href="/cgv" target="_blank" class="text-primary">Charte de Confidentialité</n-button> de
      Rentool.
    </div>
    <div class="flex w-full justify-end">
      <n-button :loading="loading" size="large" type="primary" @click="onSubmit">Créer mon compte</n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Api } from '@form-create/naive-ui';
import { useUserStore } from '@/stores';
import { registerRules } from '../formSchemas';
import { getErrorMessage } from '.';

const props = defineProps({
  close: { type: Function }
});

const userStore = useUserStore();

const loading = ref(false);
const loginModel = ref();

const fapi = ref<Api>();
const errorMsg = ref('');

async function onSubmit() {
  loading.value = true;
  try {
    const form: { email: string; password: string; firstName: string; lastName: string; phone: string } =
      await fapi.value?.submit();
    userStore
      .register(form)
      .then(() => {
        errorMsg.value = '';
        loading.value = false;
        props.close();
      })
      .catch(error => {
        console.log(error);
        errorMsg.value = getErrorMessage(error.error);
        loading.value = false;
      });
  } catch (error) {
    loading.value = false;
  }
}
</script>
