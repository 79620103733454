import { h } from 'vue';
import { useMatches } from 'vue-responsiveness';
import { openModal } from '@/utils';
import { UserGlobal, UserGlobalMenu } from '..';

export function displayUserGlobal(selectMenu: UserGlobalMenu = 'orders') {
  const matches = useMatches();
  const modal = openModal(
    {
      content: () =>
        h(UserGlobal, { selectMenu, modal, style: { padding: matches.isOnly('sm') ? '10px' : '10px 30px 30px 30px' } }),
      title: 'Mon compte utilisateur'
    },
    {
      maxWidth: '1200px'
    }
  );
}
