<template>
  <div>
    <div v-if="$matches.sm.only">
      <n-tabs type="line" animated @update:value="val => (activeKey = val)">
        <n-tab v-for="menuOption in menuOptions" :key="menuOption.key" :name="menuOption.key">
          <component :is="menuOption.icon" class="mr-1" /> {{ menuOption.label }}
        </n-tab>
      </n-tabs>
    </div>
    <div v-else>
      <div class="rounded-xl bg-white p-6">
        <p class="text-lg font-bold">Mon compte</p>
        <n-menu v-model:value="activeKey" :options="menuOptions" responsive />
      </div>
      <n-button class="w-full mt-6" type="error" tertiary size="large" @click="onDisconnect">Se déconnecter</n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { h } from 'vue';
import { NTabs, NTab, NMenu } from 'naive-ui';
import { Icon } from '@iconify/vue';

defineProps({
  onDisconnect: { type: Function }
});

const activeKey = defineModel<string>();

const menuOptions = [
  {
    key: 'orders',
    label: 'Mes réservations',
    icon: () => h(Icon, { icon: 'carbon:order-details' })
  },
  {
    key: 'informations',
    label: 'Mes informations',
    icon: () => h(Icon, { icon: 'carbon:user-avatar' })
  }
];
</script>
