export function formatCurrency(price: number) {
  const locale = 'fr-FR';
  const currency = 'EUR';

  if (!locale) return price;

  return price.toLocaleString(locale, {
    style: 'currency',
    currency
  });
}
