import { setupStore } from '@/stores';
import { initNaiveUi } from './naiveui';
import { setupFormCreate } from './formCreate';
import { initResponsive } from './responsive';

export function initPlugins() {
  return {
    naive: initNaiveUi(),
    reponsive: initResponsive,
    store: setupStore(),
    formCreate: setupFormCreate()
  };
}

export type Plugins = ReturnType<typeof initPlugins>;

export * from './stripe';
