<template>
  <div>
    <div class="font-bold mb-3">
      {{ title }}
    </div>
    <div class="flex gap-2">
      <n-button
        size="large"
        :tertiary="value !== declination.id"
        :type="value === declination.id ? 'primary' : 'default'"
        v-for="(declination, index) in declanations"
        :key="declination.id"
        :focusable="false"
        :disabled="index === 1"
        @click="() => (value = declination.id)"
      >
        {{ declination.title.title }}
      </n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ProductDeclanation } from '@/services';

const props = defineProps({
  declanations: null,
  title: null
});

const value = ref<string | null>(null);
</script>
