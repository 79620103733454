import {
  user_cart_insert_input as UserCartInsert,
  user_cart_set_input as UserCartUpdate
} from '@rentool/client-api/dist/utils';
import { useMutation } from '@/api';
import { queryCart } from '../query';

export const mutationCart = {
  add: (data: UserCartInsert) =>
    useMutation({
      insert_user_cart_one: { __args: { object: data }, ...queryCart.user_cart }
    }),
  edit: (id: string, data: UserCartUpdate) =>
    useMutation({
      update_user_cart_by_pk: { __args: { pk_columns: { id }, _set: data }, ...queryCart.user_cart }
    }),
  del: (id: string) =>
    useMutation({
      delete_user_cart_by_pk: { __args: { id }, id: true }
    })
};
