<template>
  <div class="p-8 h-full items-center">
    <div :class="{ 'pt-12': $matches.isOnly('sm') }">
      <div class="mb-6 text-lg">"{{ title }}" a bien été ajouté à votre panier.</div>
      <div class="flex gap-3 justify-center">
        <n-button type="primary" size="large" @click="close">Continuer</n-button>
        <n-button size="large" @click="closeAndDO">Accéder à la commande</n-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DialogReactive } from 'naive-ui';
import { displayOrder } from '@/layouts';

const props = defineProps({
  title: null,
  modal: null
});

function close() {
  props.modal.destroy();
}

function closeAndDO() {
  props.modal.destroy();
  displayOrder();
}
</script>

<style scoped></style>
