import { useQuery } from '@/api';

export function queryToken(token: string) {
  return useQuery({
    token: {
      __args: { where: { token: { _eq: token } } },
      orgActId: true,
      organisation_activity: {
        activity: {
          type: true
        }
      }
    }
  });
}
