import { useQuery } from '@/api';

export function queryEventS(aricleId: string, startDate: Date, endDate: Date) {
  return useQuery({
    orders_products_aggregate: {
      __args: {
        where: {
          article_id: { _eq: aricleId },
          date_start: { _eq: startDate },
          status: { _eq: true }
        }
      },
      aggregate: {
        count: true
      }
    },
    articles_by_pk: {
      __args: { id: aricleId },
      article_stocks: {
        __args: { where: { date_start: { _eq: startDate } } },
        stock: {
          quantity: true,
          article_stocks: {
            __args: { where: { date_start: { _lte: endDate }, date_end: { _gte: startDate } } },
            article_id: true,
            article: {
              orders_products_aggregate: {
                __args: {
                  where: { date_start: { _lte: endDate }, date_end: { _gte: startDate } },
                  distinct_on: ['article_id']
                },

                aggregate: {
                  count: true
                }
              }
            }
          }
        }
      }
    }
  });
}

// export function queryAllEventS(aricleId: string, date: Date) {

//   return useQuery({
//     order_event_aggregate: {
//       __args: {
//         where: { date_start: { _eq: date }, article: { stock } },
//       },
//       aggregate: {
//         count: true,
//       }
//     },
//   })
// }

export const queryEventStock = {
  get: (productId: string, startDate: Date, endDate: Date) => queryEventS(productId, startDate, endDate)
};
