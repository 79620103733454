import {
  user_cart_insert_input as UserCartInsert,
  user_cart_set_input as UserCartUpdate
} from '@rentool/client-api/dist/utils';
import { QueryResult, client, MutationResult } from '@/api';
import { getCart } from './query';
import { mutationCart } from './mutation';

const { add, edit, del } = mutationCart;

export const cartActions = {
  get: (orgActId: string): Promise<QueryResult<ReturnType<typeof getCart>>> => {
    return client().query(getCart(orgActId));
  },
  add: (data: UserCartInsert): Promise<MutationResult<ReturnType<typeof add>>> => {
    return client().mutation(add(data));
  },
  edit: (id: string, data: UserCartUpdate): Promise<MutationResult<ReturnType<typeof edit>>> => {
    return client().mutation(edit(id, data));
  },
  del: (id: string): Promise<MutationResult<ReturnType<typeof del>>> => {
    return client().mutation(del(id));
  }
};
