import { computed } from 'vue';
import { defineStore } from 'pinia';
import { organisationActions, Organisation, ActivityShop, ActivitySettings } from '@/services';

interface OrganisationState {
  /** Liste des produits */
  organisation: Organisation;
  activityShops: ActivityShop[];
  selectedShop: ActivityShop;
  settings: ActivitySettings;
}

const { get } = organisationActions;

export const useOrganisationStore = defineStore('organisation-store', {
  state: (): OrganisationState => {
    return {
      organisation: {} as Organisation,
      activityShops: [] as ActivityShop[],
      selectedShop: {} as ActivityShop,
      settings: {} as ActivitySettings
    };
  },
  getters: {
    idLoaded: state => computed(() => state.organisation.id !== undefined),
    getShopDates(state) {
      return state.selectedShop.dates;
    },
    getShopById:
      state =>
      (id: string): ActivityShop => {
        return state.activityShops.find(shop => shop.id === id) as ActivityShop;
      },
    getParams: state => state.organisation,
    getStripeAccountId: state => state.organisation.organisation_settings[0].stripe_account_id,
    getOrgId: state => state.organisation.id
  },
  actions: {
    async get(token: string) {
      const { organisation_activity: organisationAct } = await get(token);
      if (!organisationAct.length) return;
      const {
        organisation,
        shopOrgActivitiesByParentOrgActivityId: activityShops,
        activity_settings: activitySettings
      } = organisationAct[0];

      this.$state = {
        organisation,
        activityShops,
        selectedShop: activityShops[0],
        settings: activitySettings
      };
    },
    setSelectedShop(id: string) {
      const shop = this.activityShops.find(s => s.id === id);
      if (shop) this.selectedShop = shop;
    }
  }
});
