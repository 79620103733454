import { defineStore } from 'pinia';
import type { GlobalThemeOverrides } from 'naive-ui';
import { getThemeColors } from '@/config';
import type { ColorType, Colors } from '@/typings';

const defaultColors = {
  primary: '#1d4ed8',
  success: '#10b981',
  warning: '#f59e0b',
  error: '#ef4444',
  info: '#3b82f6'
};

function themeColors(mainColors: Colors): [ColorType, string][] {
  return [
    ['primary', mainColors.primary || defaultColors.primary],
    ['info', mainColors.info || defaultColors.info],
    ['success', mainColors.success || defaultColors.success],
    ['warning', mainColors.warning || defaultColors.warning],
    ['error', mainColors.error || defaultColors.error]
  ];
}

const themeOverrides: GlobalThemeOverrides = {
  common: {
    fontSize: '1rem',
    fontSizeLarge: '1.5rem',
    fontSizeMedium: '1rem',
    fontSizeSmall: '0.875rem',
    fontSizeTiny: '0.75rem',
    lineHeight: '1.3',
    ...getThemeColors(themeColors(defaultColors)),
    textColor1: '#030303'
  },
  Button: {
    borderRadiusLarge: '24px',
    borderRadiusMedium: '18px',
    borderRadiusSmall: '14px',
    borderRadiusTiny: '10px',
    textColorFocus: '',
    borderFocus: '',
    textColorTextFocus: '',
    heightLarge: '45px',
    fontSizeLarge: '1rem'
  },
  Card: {
    borderRadius: '25px',
    borderColor: '#8080802B',
    textColor: '#1A1A1A'
  },
  Input: {
    borderRadius: '20px',
    border: '1px solid #c7c7c7',
    fontSizeLarge: '1rem'
  },
  Select: {
    peers: {
      InternalSelection: {
        borderRadius: '20px',
        border: '1px solid #c7c7c7',
        fontSizeLarge: '1rem'
      }
    }
  },
  Popover: {
    borderRadius: '25px'
  },
  Table: {
    borderRadius: '10px',
    borderColor: '#DDDDDD',
    tdColor: '#DDDDDD',
    tdTextColor: '#1A1A1A'
  },
  Tabs: {
    tabBorderColor: '#8080802B',
    tabFontSizeLarge: '1.5rem',
    tabFontSizeMedium: '1rem',
    tabFontSizeSmall: '0.875rem'
  },
  Tag: {
    borderRadius: '10px'
  },
  Dropdown: {
    borderRadius: '10px',
    fontSizeLarge: '1rem'
  },
  Radio: {
    fontSizeLarge: '1rem'
  },
  Dialog: {
    padding: '0px',
    color: '#f3f2f4',
    textColor: '#1A1A1A',
    contentMargin: '0px'
  },
  Divider: {
    color: '#e5e7eb'
  },
  Form: {
    labelFontSizeLeftLarge: '1rem',
    labelFontSizeLeftMedium: '1rem'
  },
  Typography: {},
  Spin: {
    sizeSmall: '18px'
  }
};

type ThemeStoreState = GlobalThemeOverrides & {
  initColor: boolean;
};

export const useThemeStore = defineStore('theme-store', {
  state: (): ThemeStoreState => {
    return {
      ...themeOverrides,
      initColor: false
    };
  },
  getters: {
    getPrimary: state => state.common?.primaryColor || defaultColors.primary
  },
  actions: {
    setColors(colors: Colors) {
      this.initColor = true;
      this.common = { ...this.common, ...getThemeColors(themeColors(colors)) };
    }
  }
});
