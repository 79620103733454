import { App, Directive } from 'vue';

export default function setupClickOutside(app: App) {
  const clickOutside: Directive = {
    beforeMount(el, binding) {
      el.clickOutsideEvent = function (event: Event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event);
        }
      };
      setTimeout(() => {
        document.addEventListener('click', el.clickOutsideEvent);
      }, 300);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    }
  };

  app.directive('click-outside', clickOutside);
}
