<template>
  <n-button v-if="$matches.sm.only" round class="h-[44px] px-2" @click="displayOrder">
    <n-badge type="default" :value="cartStore.cart.length" :max="15" class="z-10">
      <icon-carbon:shopping-bag class="text-[30px] lg:pr-1" />
    </n-badge>
  </n-button>
  <n-popover v-else :show-icon="false" :show-arrow="false" @update:show="onShow">
    <template #trigger>
      <n-button round class="h-auto pr-1 pl-2 pr-2 py-2">
        <span class="px-2">Panier</span>
        <n-badge type="default" :value="cartStore.cart.length" :max="15" class="z-10">
          <icon-carbon:shopping-bag class="text-[1.6rem] lg:pr-1" />
        </n-badge>
      </n-button>
    </template>
    <n-card :bordered="false" class="w-[390px] p-0">
      <p class="m-0 text-xl mb-2">Votre panier</p>
      <div v-if="cartIsEmpty" class="flex justify-center rounded-xl p-4 bg-gray-1">Le panier est vide</div>
      <div v-else class="flex flex-col gap-3">
        <div
          v-for="(item, index) in cartStore.cart"
          :key="index"
          class="flex justify-between items-center rounded-xl p-4 bg-gray-1"
        >
          <div class="flex gap-1 flex-col">
            <span class="font-bold">1 x {{ item.human.article }}</span>

            <div class="text-xs">
              <span class="font-medium">{{ getUsername(item.cart.username) }}</span> - Le
              <span v-date="item.human.date"></span>
            </div>
            <div class="flex gap-2 mt-1">
              <n-button size="tiny" @click="() => deleteItem(item.id, orgId)">
                <template #icon>
                  <icon-carbon:close-large />
                </template>
                Supprimer</n-button
              >
              <n-button size="tiny" @click="() => editItem(item)">
                <template #icon>
                  <icon-carbon:edit />
                </template>
                Modifier</n-button
              >
            </div>
          </div>
          <div v-money="item.human.price" class="whitespace-nowrap"></div>
        </div>
        <div class="flex justify-end font-bold">Total: <span v-money="total" class="ml-4" /></div>
      </div>

      <template #footer>
        <n-button :disabled="cartIsEmpty" size="large" type="primary" class="w-full" @click="displayOrder">
          Voir le panier
        </n-button>
      </template>
    </n-card>
  </n-popover>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { NBadge, NPopover, NCard } from 'naive-ui';
import { displayOrder } from '@/layouts';
import { getUsername } from '@/utils';
import { useCartStore, useOrganisationStore, useConfigStore, useUserStore } from '@/stores';
import { deleteItem, editItem } from '.';

const cartStore = useCartStore();
const organisationStore = useOrganisationStore();
const configStore = useConfigStore();
const userStore = useUserStore();

const isInit = computed(() => userStore.isInit);
const user = computed(() => userStore.user);
const orgActId = computed(() => configStore.config.orgActId);
const token = computed(() => configStore.config.token);

const cartIsEmpty = computed(() => !cartStore.cart.length);

const total = computed(() => {
  return cartStore.cart.reduce((acc, item) => acc + item.human.price, 0);
});

const orgId = computed(() => configStore.getOrgActId);

function onShow(value: boolean) {
  if (value) cartStore.get(orgActId.value);
}

watch([isInit, user], async () => {
  if (isInit.value) {
    await organisationStore.get(token.value);
    cartStore.get(orgActId.value);
  }
});
</script>
