import {
  create,
  NButton,
  NCard,
  NConfigProvider,
  NImage,
  NDialogProvider,
  NH1,
  NH2,
  NH3,
  NH4,
  NDivider,
  NForm,
  NFormItem,
  NInputGroupLabel,
  NInput,
  NInputGroup,
  NInputNumber,
  NCheckbox,
  NCheckboxGroup,
  NSelect,
  NSwitch,
  NText,
  NGrid,
  NGridItem,
  NRow,
  NCol
} from 'naive-ui';

export function initNaiveUi() {
  return create({
    components: [
      NButton,
      NCard,
      NImage,
      NConfigProvider,
      NDialogProvider,
      NH1,
      NH2,
      NH3,
      NH4,
      NDivider,
      NForm,
      NFormItem,
      NInputGroup,
      NInputGroupLabel,
      NInput,
      NInputNumber,
      NCheckbox,
      NCheckboxGroup,
      NSelect,
      NSwitch,
      NText,
      NGrid,
      NGridItem,
      NRow,
      NCol
    ]
  });
}
