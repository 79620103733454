import { useQuery } from '@/api';

export function queryOrganisation(token: string) {
  return useQuery({
    organisation_activity: {
      __args: { where: { tokens: { token: { _eq: token } } } },
      organisation: {
        id: true,
        name: true,
        address: true,
        address2: true,
        city: true,
        postalcode: true,
        country: true,
        phone: true,
        medias: {
          file: {
            id: true,
            updatedAt: true
          }
        },
        organisation_settings: {
          stripe_account_id: true
        }
      },
      activity: {
        meta: {
          __args: { path: 'fr' }
        }
      },
      shopOrgActivitiesByParentOrgActivityId: {
        id: true,
        dates: true,
        presentation: {
          __args: { path: 'title' }
        },
        medias: {
          file: {
            id: true,
            updatedAt: true
          }
        },
        shop: {
          name: true,
          address: true,
          address2: true,
          city: true,
          postalcode: true,
          country: true,
          phone: true,
          gps: true,
          languages: true
        }
      },
      activity_settings: {
        params: true
      }
    }
  });
}
