<template>
  <n-button
    class="w-full justify-between h-full px-3 min-w[120px] transition-none rounded-full"
    :quaternary="!focused"
    icon-placement="right"
    :type="focused ? 'primary' : 'default'"
    :class="{ 'focused-menu-btn': focused }"
  >
    <div class="flex w-full flex-col p-3 items-start">
      <div class="font-bold text-sm mb-1 opacity-90">Magasin</div>
      <n-ellipsis
        style="max-width: 90px; line-height: normal"
        :class="{ 'opacity-70': !selectOption, 'font-bold': selectOption }"
      >
        {{ selectOption ? selectOption : 'Sélectionner' }}
      </n-ellipsis>
    </div>
  </n-button>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { NEllipsis } from 'naive-ui';
import { useOrganisationStore } from '@/stores';

defineProps({
  focused: { type: Boolean }
});

const organisationStore = useOrganisationStore();

const selectOption = computed(() => {
  return organisationStore.selectedShop.shop.name;
});
</script>
