import type { App } from 'vue';
import setupFormatCurrency from './formatCurrency';
import setupFormatDate from './formatDate';
import setupClickOutside from './clickOutside';

export function setupDirectives(app: App) {
  setupFormatCurrency(app);
  setupFormatDate(app);
  setupClickOutside(app);
}
