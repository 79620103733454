<template>
  <div
    class="flex h-auto p-2 text-left"
    :style="hover ? 'background-color: #CCCCCC2F' : ''"
    @mouseenter="() => (mouseOn = true)"
    @mouseleave="() => (mouseOn = false)"
  >
    <div>
      <image-carousel
        class="w-[210px]"
        :medias="shop.medias"
        :alt-text="shop.shop.name"
        :height="100"
        :width="200"
        :mouse-on="false"
      />
      <div class="py-1 font-bold">
        {{ shop.shop.name }}
      </div>
      <div class="leading-4 text-xs">{{ shop.shop.address }}</div>
      <div
        class="mt-2 flex gap-3 items-center overflow-hidden transition-all duration-300 ease-in-out"
        :class="{
          'h-0': !mouseOn,
          'h-10': mouseOn
        }"
      >
        <n-button tertiary @click="$emit('selectShopInfo', shop.id)">En savoir +</n-button>
        <n-button type="primary" @click="$emit('select', shop.id)">Sélectionner</n-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ActivityShop } from '@/services';

defineProps({
  shop: null,
  hover: { type: Boolean }
});

defineEmits(["select", "selectShopInfo"]);

const mouseOn = ref(false);
</script>
