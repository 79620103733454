import { h } from 'vue';
import formCreate from '@form-create/naive-ui';
import { FormPhone } from '@/components/common';

export function setupFormCreate() {
  // formCreate.component('UserPerms', h(UserPerms));
  // formCreate.component('UploadFiles', h(UploadFiles));
  // formCreate.component('MdEditor', h(MdEditor));
  // formCreate.component('I8nGroup', h(I8nGroup));
  // formCreate.component('FormTabs', h(FormTabs));
  // formCreate.component('FormWeeks', h(FormWeeks));
  // formCreate.component('FormMultipleTime', h(FormMultipleTime));
  formCreate.component('FormPhone', h(FormPhone));
  // formCreate.component('FormCountry', h(FormCountry));
  // formCreate.component('FormUser', h(FormUser));
  // formCreate.component('FormArray', h(FormArray));

  formCreate.factory();

  return formCreate;
}
