<template>
  <div class="w-[750px] h-auto flex p-8 items-center justify-center">
    <r-calendar
      v-model="dates"
      :month-nb="2"
      model="range"
      :start-day="limitDates.start"
      :end-day="limitDates.end"
      @date-select="() => $emit('update:show-popover', true)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RCalendar } from '@/components/common';
import { useCartStore, useOrganisationStore } from '@/stores';

defineEmits(["update:show-popover"]);

const cartStore = useCartStore();
const organisationStore = useOrganisationStore();

const dates = computed({
  get: () => {
    const { start, end } = cartStore.datesSelection;
    return [start, end] as [Date | undefined, Date | undefined];
  },
  set: (val: [Date | undefined, Date | undefined]) => {
    const [start, end] = val;
    cartStore.setDate({ start, end });
  }
});

const limitDates = computed(() => {
  const [startDay, endDay] = organisationStore.selectedShop.dates[0].dates;
  const start = new Date(startDay);
  const end = new Date(endDay);
  return {
    start,
    end
  };
});
</script>

<style scoped></style>
