import { Gql, AuthRolesType } from '@rentool/client-api';
import { useConfigStore } from '@/stores';

export { useQuery, useMutation } from '@rentool/client-api';
export type { QueryResult, MutationResult } from '@rentool/client-api';

const client = (role: AuthRolesType = 'public') => {
  const token = useConfigStore().getToken;
  return Gql({ role, app: 'client', token });
};

export { client };
