import type { Ref } from 'vue';
import type { FormItemRule } from 'naive-ui';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

/** Numéro de téléphone */
const REGEXP_PHONE = /^((\+)33|0|0033)[1-9](\d{2}){4}$/;
/** Email */
const REGEXP_EMAIL = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
/** Mot de passe (le mot de passe est une combinaison de 8-18 chiffres / caractères / symboles) */
const REGEXP_PWD =
  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[()])+$)(?!^.*[\u4E00-\u9FA5].*$)([^(0-9a-zA-Z)]|[()]|[a-z]|[A-Z]|[0-9]){8,18}$/;
/** Code de vérification à 6 chiffres */
const REGEXP_CODE_SIX = /^\d{6}$/;
/** Code de vérification à 4 chiffres */
// const REGEXP_CODE_FOUR = /^\d{4}$/;
// /** URL (lien) */
// const REGEXP_URL =
//   /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

/** Règle de formulaire */
interface CustomFormRules {
  /** numéro de téléphone */
  phone: FormItemRule[];
  /** le mot de passe */
  pwd: FormItemRule[];
  /** Code de vérification */
  code: FormItemRule[];
  /** Envoyer */
  email: FormItemRule[];
  /** input par defaut */
  input: FormItemRule[];
  number: FormItemRule[];

  date: FormItemRule[];
}

/** Règle de formulaire */
export const formRules: CustomFormRules = {
  date: [{ type: 'array', required: true, trigger: ['change', 'input'], message: 'Ce champ est requis' }],
  input: [{ required: true, message: 'Ce champ est requis', trigger: ['input', 'change'] }],
  number: [{ type: 'number', required: true, message: 'Ce champ est requis', trigger: ['input', 'change'] }],
  phone: [
    { required: true, message: 'Entrer un numéro de téléphone', trigger: ['input'] },
    {
      validator: (rule, value) => {
        if (value === undefined) {
          return Promise.reject();
        }
        const phoneNumber = parsePhoneNumberFromString(value);

        if (!phoneNumber || !phoneNumber.isValid()) {
          return Promise.reject(rule.message);
        }
        return Promise.resolve();
      },
      message: 'Veuillez saisir un numéro de téléphone valide',
      trigger: 'blur'
    }
  ],
  pwd: [
    { required: true, message: 'Entrez votre mot de passe' },
    {
      pattern: REGEXP_PWD,
      message: '8-18 chiffres / caractères',
      trigger: 'input'
    }
  ],
  code: [
    { required: true, message: 'Code de vérification' },
    { pattern: REGEXP_CODE_SIX, message: 'Code de vérification incorrect', trigger: 'input' }
  ],
  email: [
    { required: true, message: 'Entrer une adresse email', trigger: ['input'] },
    { pattern: REGEXP_EMAIL, message: 'Veuillez saisir une adresse email valide', trigger: 'blur' }
  ]
};

/** Obtenir des règles de formulaire pour confirmer votre mot de passe */
export function getConfirmWeekDays() {
  const confirmWeekDays: FormItemRule[] = [
    { required: true, message: 'Veuillez sélectionner un jour' },
    {
      validator: (rule, value) => {
        if (value === undefined) {
          return Promise.reject();
        }
        if (!value.find((v: boolean) => v)) {
          return Promise.reject(rule.message);
        }
        return Promise.resolve();
      },
      message: 'Veuillez sélectionner un jour',
      trigger: ['change', 'input', 'focus']
    }
  ];
  return confirmWeekDays;
}

/** Obtenir des règles de formulaire pour confirmer votre mot de passe */
export function getConfirmPwdRule(pwd: Ref<string>) {
  const confirmPwdRule: FormItemRule[] = [
    { required: true, message: 'Entrer un mot de passe de confirmation' },
    {
      validator: (rule, value) => {
        if (!isBlankString(value) && value !== pwd.value) {
          return Promise.reject(rule.message);
        }
        return Promise.resolve();
      },
      message: 'Le mote de passe n`est pas le même',
      trigger: 'input'
    }
  ];
  return confirmPwdRule;
}

/** Obtenir une règle de formulaire pour le code de vérification d'image */
export function getImgCodeRule(imgCode: Ref<string>) {
  const imgCodeRule: FormItemRule[] = [
    { required: true, message: 'Entrer le code de vérification' },
    {
      validator: (rule, value) => {
        if (!isBlankString(value) && value !== imgCode.value) {
          return Promise.reject(rule.message);
        }
        return Promise.resolve();
      },
      message: 'Code de vérification incorrect',
      trigger: 'blur'
    }
  ];
  return imgCodeRule;
}

/** Est-ce une chaîne vide */
function isBlankString(str: string) {
  return str.trim() === '';
}
