import type { Rule } from '@form-create/naive-ui';
import { Product } from '@/services';
import { getOptionSelect } from './optionsSelect';
import { getBodySelect } from './bodySelect';
import { checkPriceVariation } from './checkPriceVariation';

export function ruleCart(article: Product, date: Date): Rule[] {
  const labelWidth = '200px';
  return [
    ...checkPriceVariation(article, labelWidth),
    ...getBodySelect(article, labelWidth),
    ...getOptionSelect(article, date, labelWidth)
  ];
}
