import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import type { HsvColor } from 'colord';

extend([mixPlugin]);

type ColorIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

const hueStep = 2;
const saturationStep = 16;
const saturationStep2 = 5;
const brightnessStep1 = 5;
const brightnessStep2 = 15;
const lightColorCount = 5;
const darkColorCount = 4;

/**
 * Obtenez la couleur en fonction de la couleur actuelle (de gauche à droite de la lumière de la lumière à la profonde, 6 est le numéro de couleur principal)
 * @param color - Couleur
 * @param index - Le numéro de couleur correspondant (6 est le principal numéro de couleur)
 * @description  https://github.com/ant-design/ant-design/blob/master/components/style/color/colorPalette.less
 */

export function getColorPalette(color: string, index: ColorIndex) {
  if (index === 6) return color;

  const isLight = index < 6;
  const hsv = colord(color).toHsv();
  const i = isLight ? lightColorCount + 1 - index : index - lightColorCount - 1;

  const newHsv: HsvColor = {
    h: getHue(hsv, i, isLight),
    s: getSaturation(hsv, i, isLight),
    v: getValue(hsv, i, isLight)
  };

  return colord(newHsv).toHex();
}

/**
 * Obtenez toutes les couleurs du panneau de couleur en fonction de la couleur
 * @param color - Couleur
 */
export function getAllColorPalette(color: string) {
  const indexs: ColorIndex[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return indexs.map(index => getColorPalette(color, index));
}

/**
 * Obtenir le dégradé de couleurs
 * @param hsv - Valeur de couleur du format HSV
 * @param i - Distance relativement de 6
 * @param isLight - Que ce soit une couleur vive
 */
function getHue(hsv: HsvColor, i: number, isLight: boolean) {
  let hue: number;
  if (hsv.h >= 60 && hsv.h <= 240) {
    // Couleurs froides
    // Tourner la lumière et la teinte, la rotation dans le sens horaire est plus chaude
    // Approfondir la teinte sombre et la rotation dans le sens horaire est plus froide
    hue = isLight ? hsv.h - hueStep * i : hsv.h + hueStep * i;
  } else {
    // Couleur chaude
    // Tourner la lumière et tourner la couleur et la rotation dans le sens des aiguilles d'une montre est plus chaude
    // Approfondir la teinte sombre et la rotation dans le sens horaire est plus froide
    hue = isLight ? hsv.h + hueStep * i : hsv.h - hueStep * i;
  }
  if (hue < 0) {
    hue += 360;
  } else if (hue >= 360) {
    hue -= 360;
  }
  return hue;
}

/**
 * Obtenez un gradient saturé
 * @param hsv - Valeur de couleur du format HSV
 * @param i - Distance relativement de 6
 * @param isLight - Que ce soit une couleur vive
 */
function getSaturation(hsv: HsvColor, i: number, isLight: boolean) {
  let saturation: number;
  if (isLight) {
    saturation = hsv.s - saturationStep * i;
  } else if (i === darkColorCount) {
    saturation = hsv.s + saturationStep;
  } else {
    saturation = hsv.s + saturationStep2 * i;
  }
  if (saturation > 100) {
    saturation = 100;
  }
  if (isLight && i === lightColorCount && saturation > 10) {
    saturation = 10;
  }
  if (saturation < 6) {
    saturation = 6;
  }
  return saturation;
}

/**
 * Obtenir un gradient de luminosité
 * @param hsv - Valeur de couleur du format HSV
 * @param i - Distance relativement de 6
 * @param isLight - Que ce soit une couleur vive
 */
function getValue(hsv: HsvColor, i: number, isLight: boolean) {
  let value: number;
  if (isLight) {
    value = hsv.v + brightnessStep1 * i;
  } else {
    value = hsv.v - brightnessStep2 * i;
  }
  if (value > 100) {
    value = 100;
  }
  return value;
}

/**
 * Ajout de la transparence à la couleur
 * @param color - Couleur
 * @param alpha - transparence(0 - 1)
 */
export function addColorAlpha(color: string, alpha: number) {
  return colord(color).alpha(alpha).toHex();
}

/**
 * Mélange de couleurs
 * @param firstColor - Première couleur
 * @param secondColor - Deuxième couleur
 * @param ratio - La deuxième proportion de couleur
 */
export function mixColor(firstColor: string, secondColor: string, ratio: number) {
  return colord(firstColor).mix(secondColor, ratio).toHex();
}

/**
 * Si c'est de la couleur blanche
 * @param color - Couleur
 */
export function isWhiteColor(color: string) {
  return colord(color).isEqual('#ffffff');
}

/**
 * Get color (black/white) depending on bgColor so it would be clearly seen.
 */
export function getColorByBgColor(hex: string) {
  const hexCode = hex.charAt(0) === '#' ? hex.slice(1, 7) : hex;
  const hexR = parseInt(hexCode.slice(0, 2), 16);
  const hexG = parseInt(hexCode.slice(2, 4), 16);
  const hexB = parseInt(hexCode.slice(4, 6), 16);
  const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

  return contrastRatio >= 0.58 ? 'black' : 'white';
}
