<template>
  <div class="flex gap-2 flex-col <lg:w-full">
    <div>Location de {{ nbDays }} jours</div>
    <div class="rounded-xl bg-gray-1 p-4 box-border flex gap-8 justify-between items-center flex-1">
      <div v-if="dates?.length">
        <div>Du {{ formatDate(dates[0], formatD) }}</div>
        <div>Au {{ formatDate(dates[1], formatD) }}</div>
      </div>
      <n-button type="primary" ghost @Click="toggleCalendar">Modifier</n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useMatches } from 'vue-responsiveness';
import { getLocalDate, formatDate } from '@/utils';

const props = defineProps({
  dates: null,
  toggleCalendar: { type: Function }
});

const nbDays = computed(() => {
  if (!props.dates) return 0;
  const [start, end] = props.dates;
  return getLocalDate(end).diff(getLocalDate(start), 'days') + 1;
});

const formatD = computed(() => (useMatches().isOnly('sm') ? 'ddd D MMM YYYY' : 'dddd D MMMM YYYY'));
</script>
