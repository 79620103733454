<template>
  <div class="p-1 box-border bg-gray-200/70 rounded-full h-full">
    <div class="relative h-full">
      <div class="relative z-10 flex gap-1 h-full">
        <n-button
          v-for="option in options"
          :key="option.value"
          quaternary
          class="flex items-center h-full rounded-full"
          :class="{ 'active-b': capsuleValue === option.value }"
          @click="(e: MouseEvent) => updateValue(option.value)"
          >{{ option.label }}</n-button
        >
      </div>

      <div
        v-show="capsuleValue"
        ref="capsule"
        class="shadow-sm transition-transform rounded-full absolute top-0 left-0 h-full bg-white"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

const props = defineProps({
  modelValue: null,
  options: null
});

const emit = defineEmits(["update:modelValue"]);

const capsule = ref<HTMLElement>();
const capsuleValue = ref<string>();

function updateValue(value: string) {
  emit('update:modelValue', [value]);
}

function updateCapsule() {
  let index = props.options.findIndex(option => props.modelValue.includes(option.value)) || 0;
  if (index < 0) index = 0;

  const capsuleEl = capsule.value;
  if (!capsuleEl) return;

  const button = capsuleEl.parentElement?.querySelectorAll('button')[index];
  if (!button) return;
  capsuleEl.style.width = `${button.clientWidth}px`;
  capsuleEl.style.transform = `translateX(${button.offsetLeft}px)`;

  [capsuleValue.value] = props.modelValue;

  if (!capsuleValue.value) {
    capsuleValue.value = props.options[index].value;
  }
}

watch(
  () => props.modelValue,
  () => {
    updateCapsule();
  }
);

onMounted(() => {
  updateCapsule();
});
</script>
<style>
.active-b {
  background-color: transparent !important;
}
</style>
