<template>
  <div v-if="options.length">
    <p class="font-bold mb-3">Options disponibles</p>
    <div class="flex gap-2">
      <n-button
        size="large"
        class="h-auto w-auto p-0"
        ghost
        v-for="option in options"
        :key="option.article.id"
        :focusable="false"
        @click="() => (value = value ? undefined : option.article.id)"
      >
        <div class="w-full">
          <image-carousel
            :medias="option.article.medias"
            :alt-text="option.article.description.title"
            :height="80"
            object-fit="contain"
          />
          <div class="p-4 flex gap-2 items-center w-full">
            <n-checkbox size="large" :checked="option.article.id === value" />
            <span class="text-sm text-wrap">{{ option.article.description.title }}</span>
          </div>
        </div>
      </n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductOptions } from '@/services';
import { ref } from 'vue';

defineProps({
  options: null
});

const value = ref<string | null>(null);
</script>
