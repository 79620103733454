<template>
  <div>
    <auto-form v-model="loginModel" :rule="loginRules" @update:fapi="value => (fapi = value)" />
    <div v-if="errorMsg" class="flex justify-end text-red-700 mb-4">
      {{ errorMsg }}
    </div>
    <div class="flex w-full justify-end">
      <n-button :loading="loading" size="large" type="primary" @click="onSubmit">Se connecter</n-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Api } from '@form-create/naive-ui';
import { useUserStore } from '@/stores';
import { loginRules } from '../formSchemas';
import { getErrorMessage } from '.';

const props = defineProps({
  close: { type: Function }
});

const userStore = useUserStore();

const loading = ref(false);
const loginModel = ref({
  email: '',
  password: ''
});

const fapi = ref<Api>();
const errorMsg = ref('');

async function onSubmit() {
  loading.value = true;
  try {
    const form: { email: string; password: string } = await fapi.value?.submit();
    userStore
      .login(form)
      .then(result => {
        errorMsg.value = '';
        loading.value = false;
        props.close();
      })
      .catch(error => {
        errorMsg.value = getErrorMessage(error.error);
        loading.value = false;
      });
  } catch (error) {
    loading.value = false;
  }
}
</script>
../UserAccount/formSchemas
