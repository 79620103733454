import type { Rule } from '@form-create/naive-ui';
import { formRules } from '@/utils';
import { Product } from '@/services';

export function checkPriceVariation(article: Product, labelWidth: string): Rule[] {
  return [
    article.price?.data.length && article.price.variation === 'quantity'
      ? {
          type: 'select',
          field: 'quantity',
          title: article.price.label.label,
          options: article.price.data.map((_p: any, index: number) => ({ value: index + 1, label: index + 1 })),
          validate: formRules.number,
          props: {
            size: 'large',
            placeholder: 'Choisir une option'
          },
          wrap: {
            labelWidth
          },
          control: [
            {
              handle(val: number) {
                return val > 0;
              },
              rule: [
                {
                  type: 'subForm',
                  field: 'username',
                  title: '',
                  props: {
                    rule: []
                  }
                }
              ]
            }
          ],
          update(val, rule, api) {
            if (val)
              api.updateRule('username', {
                type: 'subForm',
                field: 'username',
                title: '',
                props: {
                  rule: new Array(val).fill('a').map((_v, index) => ({
                    type: 'input',
                    field: `name${index + 1}`,
                    title: `Prénom ${index + 1}`,
                    validate: formRules.input,
                    props: {
                      placeholder: 'Prénom',
                      clearable: true,
                      size: 'large'
                    },
                    wrap: {
                      labelWidth
                    }
                  }))
                }
              });
          }
        }
      : {
          type: 'input',
          field: 'username',
          title: 'Prénom',
          validate: formRules.input,
          props: {
            placeholder: 'Prénom',
            clearable: true,
            size: 'large'
          },
          wrap: {
            labelWidth
          }
        }
  ];
}
