import { App, Directive, DirectiveBinding } from 'vue';
import { formatDate } from '@/utils';
/* eslint no-param-reassign: "error" */

function injectDate(el: HTMLElement, binding: DirectiveBinding<any>) {
  const format = el.getAttribute('format') || undefined;
  if (binding.value) {
    el.innerText = formatDate(binding.value, format);
  }
}

export default function setupFormatCurrency(app: App) {
  const getDate: Directive<HTMLElement, any | undefined> = {
    updated(el, binding) {
      injectDate(el, binding);
    },
    mounted(el, binding) {
      injectDate(el, binding);
    }
  };

  app.directive('date', getDate);
}
