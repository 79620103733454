import { h } from 'vue';
import { OrderProcess, deleteItem, editItem } from '@/layouts';
import { openModal } from '@/utils';
import { useCartStore } from '@/stores';

export function displayOrder() {
  const cartStore = useCartStore();

  const modal = openModal(
    {
      content: () =>
        h(OrderProcess, {
          cart: cartStore.cart,
          close: () => modal.destroy(),
          deleteItem,
          editItem
        })
    },
    {
      maxWidth: '950px'
    }
  );
}
