<template>
  <to-card-confirm v-if="isToCart" :title="product?.description.title" :modal="modal" />
  <div v-else-if="product">
    <div>
      <product-form
        v-if="selectEvent"
        :product-id="product.id"
        :date-start="selectEvent"
        :scroll-to="scrollTo"
        :back="() => back()"
        :close="closeModal"
        :modal="modal"
        :resize="modalWidth => resize(modalWidth)"
        :is-to-cart="status => (isToCart = status)"
        :class="$matches.sm.only ? 'p-2' : 'p-6'"
      />
      <div v-else class="flex flex-col" :class="$matches.sm.only ? 'p-2' : 'p-6'">
        <div class="mb-3 flex gap-6" :class="{ 'flex-col mt-0': $matches.sm.only }">
          <image-carousel
            v-if="!$matches.sm.only"
            :medias="product.medias"
            :alt-text="product.description.title"
            :height="450"
            :width="800"
            class="flex-1"
            :mouse-on="mouseOn"
            @mouseenter="() => (mouseOn = true)"
            @mouseleave="() => (mouseOn = false)"
          />
          <div class="flex flex-1 flex-col justify-between">
            <div class="flex flex-col gap-3">
              <image-carousel
                v-if="$matches.sm.only"
                :medias="product.medias"
                :alt-text="product.description.title"
                :height="330"
                :width="800"
                :mouse-on="mouseOn"
                @mouseenter="() => (mouseOn = true)"
                @mouseleave="() => (mouseOn = false)"
              />
              <div class="bg-white p-2 rounded-xl">
                <div class="flex w-full justify-between">
                  <div class="p-2">
                    <p class="mb-4 text-xl font-bold">
                      {{ product.description.title }}
                    </p>
                    <product-attributes
                      v-if="useActivityType().type === 'school'"
                      :product="product"
                      class="text-sm opacity-80"
                    />
                    <product-categories v-else :product="product" class="text-sm opacity-70" />
                  </div>
                  <product-price :price="price" :loading="loading" />
                </div>
              </div>
              <div class="bg-white p-4 rounded-xl pt-6">
                <div class="font-bold">
                  {{ product.description.slogan }}
                </div>
                <div class="text-sm leading-tight mt-2 whitespace-pre-wrap opacity-80">
                  {{ product.description.description }}
                </div>
                <product-tabs v-if="useActivityType().type === 'school'" class="mt-6" :product="product" />
                <template v-else>
                  <product-declination
                    class="mt-6"
                    v-if="product.declinations.length"
                    :title="product.declination?.title"
                    :declanations="product.declinations"
                  />
                  <product-option v-if="product.child" class="mt-6" :options="product.child" />
                </template>
              </div>
              <div class="bg-white p-4 rounded-xl">
                <div class="flex-1 self-end">
                  <p class="font-bold mb-4">Choisissez un jour et une heure</p>
                  <div class="w-full flex items-center justify-between mb-6">
                    <picker-date
                      v-if="checkopenshop"
                      v-model="selectedDate"
                      :model="useActivityType().type === 'school' ? 'single' : 'range'"
                      format="ddd DD MMMM YYYY"
                      :start-day="new Date(startEndDates.start)"
                      :end-day="new Date(startEndDates.end)"
                    >
                      <template v-if="useActivityType().type === 'rental'" #default="{ date, toggleCalendar }">
                        <product-dates :dates="date" :toggle-calendar="toggleCalendar" />
                      </template>
                    </picker-date>
                  </div>
                  <product-events
                    v-if="useActivityType().type === 'school'"
                    v-model="selectedDate"
                    :product="product"
                    class="mt-4"
                    @update:event-date="date => (selectEvent = date)"
                  />
                  <div v-else class="flex w-full justify-end">
                    <n-button
                      v-if="checkopenshop"
                      type="primary"
                      icon-placement="right"
                      size="large"
                      class="px-12"
                      :focusable="false"
                      @click="() => (selectEvent = selectedDate[0])"
                    >
                      <template #icon>
                        <icon-carbon:arrow-right class="text-white" />
                      </template>
                      Réserver
                    </n-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-50% from-white ... flex pt-12">
          <div class="p-2 flex-1">
            <n-button type="primary" icon-placement="right" size="large" class="w-full rounded-full h-[50px]">
              <template #icon>
                <icon-carbon:arrow-right class="text-white" />
              </template>
              Réserver</n-button
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, toRaw, onBeforeUnmount, watch } from 'vue';
import type { DialogReactive, ScrollbarInst } from 'naive-ui';
import { ProductEvents, ProductForm, ToCardConfirm } from '@/layouts';
import { ImageCarousel } from '@/components';
import { useActivityType } from '@/composables';
import { dayjs, isArray, getLocalDate } from '@/utils';
import { useCartStore, useOrganisationStore, useProductsStore } from '@/stores';
import { getProductPrice, getDiscount } from '@/layouts/RProducts/utils';
import { PickerDate } from '@/components/common';
import type { Product } from '@/services';
import {
  ProductAttributes,
  ProductTabs,
  ProductCategories,
  ProductDeclination,
  ProductOption,
  ProductPrice,
  ProductDates
} from './components';

const props = defineProps({
  productId: null,
  resize: { type: Function },
  modal: null,
  scrollTo: null
});

const productsStore = useProductsStore();
const organisationStore = useOrganisationStore();
const cartStore = useCartStore();

const selectedDate = ref<Date | [Date | undefined, Date | undefined]>(
  useActivityType().type === 'school'
    ? getLocalDate().startOf('day').toDate()
    : [
        cartStore.datesSelection.start || getLocalDate().startOf('day').toDate(),
        cartStore.datesSelection.end || getLocalDate().startOf('day').toDate()
      ]
);

const checkopenshop = organisationStore.getShopDates.length
  ? getLocalDate().isBefore(getLocalDate(organisationStore.getShopDates[0].dates[1]))
  : false;

const mouseOn = ref(false);
const isToCart = ref(false);
const loading = ref(false);

const startEndDates = computed(() => {
  const [start, end] = organisationStore.getShopDates[0].dates;

  return { start, end };
});

const product = ref<Product>();
const selectEvent = ref<Date>();

const rentalDays = computed(() => {
  if (isArray(selectedDate.value)) {
    const [start, end] = selectedDate.value;
    return start && end ? getLocalDate(end).diff(getLocalDate(start), 'day') : 0;
  }
  return 0;
});

const discount = computed(() => (product.value?.discount ? getDiscount(product.value.discount) : 0));
const price = computed(() => getProductPrice(product.value, discount.value, rentalDays.value));

props.modal.onClose = () => {
  closeModal();
};

const back = () => {
  if (selectEvent.value) {
    selectEvent.value = undefined;
    props.modal.editData({
      backIcon: true,
      close: () => props.modal.destroy()
    });
    props.modal.style.maxWidth = '1400px';
  }
};

const closeModal = () => {
  props.modal.destroy();
  window.history.back();
};

const handlePopState = (event: PopStateEvent) => {
  if (event.state && event.state.modalOpen) {
    // if (selectEvent.value) { selectEvent.value = undefined; return }
    props.modal.destroy();
  }
};
window.history.replaceState({ modalOpen: true }, '');

watch(selectedDate, async () => {
  if (selectedDate.value[0] && selectedDate.value[1]) {
    loading.value = true;
    const test = await productsStore.getProductById(props.productId, selectedDate.value[0], true);
    product.value = toRaw(test);
    loading.value = false;
  }
});

onMounted(async () => {
  // product.value = await productsStore.getProductById(props.productId);
  window.addEventListener('popstate', handlePopState);

  const test = await productsStore.getProductById(props.productId, selectedDate.value);
  product.value = toRaw(test);

  window.history.pushState({ modalOpen: true }, '');
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handlePopState);
});
</script>
