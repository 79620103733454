<template>
  <n-button
    size="large"
    type="primary"
    class="mt-5"
    :class="{ 'w-full': $matches.sm.only }"
    :loading="loading"
    @click="onValidPayment"
    >Confirmer ma réservartion</n-button
  >
</template>

<script setup lang="ts">
import { ref, h } from 'vue';
import { openModal } from '@/utils';
import { orderAction } from '@/services';
import { useCartStore, useConfigStore } from '@/stores';
import SuccessMessage from './SuccessMessage.vue';

const props = defineProps({
  close: { type: Function }
});

const cartStore = useCartStore();
const configStore = useConfigStore();

const loading = ref(false);

async function onValidPayment() {
  loading.value = true;
  const { createCustommerOrder } = await orderAction.add();

  // create an await delay to simulate the loading
  await new Promise(resolve => setTimeout(resolve, 1500));

  loading.value = false;
  if (createCustommerOrder?.id) {
    cartStore.get(configStore.config.orgActId);
    props.close();

    const modal = openModal(
      {
        content: () =>
          h(SuccessMessage, {
            close: () => modal.destroy()
          })
      },
      {
        maxWidth: '800px'
      }
    );
  }
}
</script>
