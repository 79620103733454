import { useMutation } from '@/api';

export const mutationOrder = useMutation({
  createCustommerOrder: {
    id: true
  }
});

export function mutationPayment(orderId: string, paymentMethodId: string) {
  return useMutation({
    custommerPayment: {
      __args: {
        object: {
          orderId,
          paymentMethodId
        }
      },
      status: true
    }
  });
}
