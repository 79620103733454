<template>
  <div class="flex items-end flex-wrap gap-1">
    <template v-for="categorieType in productCategories" :key="categorieType.id">
      <template v-if="categorieType.categories.length === 1">
        <div class="flex gap-1 flex-wrap">
          <div
            v-for="categorie in categorieType.categories"
            :key="categorie.id"
            class="px-3 py-2 bg-gray-1 rounded-xl font-bold w-fit whitespace-nowrap"
          >
            {{ categorie.title.title }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useCategoriesStore } from '@/stores';
import { Product } from '@/services';

const props = defineProps({
  product: null
});

const categoriesStore = useCategoriesStore();

const productCategories = computed(() => {
  return categoriesStore.categories
    .map(category => ({
      ...category,
      categories: category.categories.filter(categorie =>
        props.product.article_categories.some(ac => ac.categorie_id === categorie.id)
      )
    }))
    .filter(category => category.categories.length > 0);
});
</script>
