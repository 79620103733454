<template>
  <div>
    <Transition name="rtool-message-trs">
      <n-card v-if="msg" rounded-2xl class="min-w-64 bg-green-200 shadow" content-style="padding:8px 8px 8px 20px;">
        <slot>
          <div class="flex justify-between items-center">
            <span>{{ msg }}</span
            ><n-button class="ml-4" quaternary circle strong @click="close"><icon-carbon:close /></n-button>
          </div>
        </slot>
      </n-card>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps({
  message: { default: 'true' },
  type: { default: 'info' },
  duration: null
});

const emit = defineEmits(["update:close"]);

const msg = ref();

function close() {
  msg.value = '';
  setTimeout(() => {
    emit('update:close', false);
  }, 300);
}

onMounted(async () => {
  msg.value = props.message;

  // set Primise timeout to close the message
  await new Promise(resolve =>
    setTimeout(() => {
      msg.value = '';
      return resolve;
    }, props.duration - 300)
  );

  await new Promise(resolve =>
    setTimeout(() => {
      emit('update:close', false);
      return resolve;
    }, 300)
  );
});
</script>

<style scoped>
.rtool-message-trs-enter-active,
.rtool-message-trs-leave-active {
  transition:
    opacity 0.2s ease,
    transform 0.3s ease;
}

.rtool-message-trs-enter-from,
.rtool-message-trs-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
