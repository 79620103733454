<template>
  <template v-if="!hideAll">
    <div v-if="!options.length" class="flex-1 h-[64px]" />
    <div v-else class="flex gap-5 justify-center w-full overflow-hidden" :class="noShadow ? 'p-1' : 'px-2 py-4'">
      <n-popover
        placement="bottom"
        :flip="false"
        :show="showPopover"
        trigger="click"
        :to="false"
        :raw="true"
        class="rounded-xl overflow-hidden bg-white"
        @clickoutside="ckilckOutside"
      >
        <template #trigger>
          <div
            v-if="!$matches.sm.only"
            class="flex items-center gap-1 rounded-full"
            :class="{ 'shadow-menu bg-white': !noShadow }"
          >
            <template v-for="option in options" :key="option.id">
              <div v-if="!hideCategories?.includes(option.id) && option.mode !== 'hide'" class="flex-1 flex h-full">
                <div class="mx-3 my-2" v-if="option.mode === 'switch'">
                  <option-buttons v-model="option.value" :options="option.options" />
                </div>

                <div v-else ref="menuButtons">
                  <button-shops
                    v-if="option.mode === 'shops'"
                    v-model="option.value"
                    :focused="selectMenu === 'shops'"
                    @click="() => displayMenu('shops')"
                  />
                  <button-menu
                    v-if="option.mode === 'select'"
                    v-model="option.value"
                    :focused="selectMenu === option.id"
                    :catetegorie-id="option.id"
                    @click="() => displayMenu(option.id)"
                  />
                  <button-dates
                    v-if="option.mode === 'dates'"
                    v-model="option.value"
                    :focused="selectMenu === 'dates'"
                    @click="() => displayMenu('dates')"
                  />
                </div>
              </div>
            </template>
          </div>
          <div v-else></div>
        </template>
        <component
          :is="popComponent(selectMenu)"
          v-if="selectOption"
          v-model="selectOption.value"
          :catetegorie-id="selectOption.id"
          @update:show-popover="updatePopover"
        />
        <div v-else></div>
      </n-popover>
    </div>
  </template>
</template>

<script setup lang="ts">
import { watch, ref, computed, nextTick, toRefs } from 'vue';
import { NPopover } from 'naive-ui';
import { OptionMenu } from '@/components';
import { useCategoriesStore, useOrganisationStore, useConfigStore } from '@/stores';
import { ButtonMenu, ButtonShops, OptionShops, ButtonDates, CalendarDates } from '.';

const props = defineProps({
  preselected: null,
  noShadow: { type: Boolean },
  hideCategories: null,
  hideAll: { type: Boolean }
});

const categoriesStore = useCategoriesStore();
const organisationStore = useOrganisationStore();
const configStore = useConfigStore();

const options = computed(() => categoriesStore.selected);

const { preselected } = toRefs(props);

let delayPopover: ReturnType<typeof setTimeout>;

function popComponent(id: string | undefined) {
  switch (id) {
    case 'select':
      return OptionMenu;
    case 'shops':
      return OptionShops;
    case 'dates':
      return CalendarDates;
    default:
      return OptionMenu;
  }
}

const showPopover = ref();

const selectMenu = ref<string>();
const menuButtons = ref<EventTarget[]>([]);

const selectOption = computed(() => {
  return options.value.length ? options.value.find(cat => cat.id === selectMenu.value) : undefined;
});

const shopId = computed(() => {
  return organisationStore.selectedShop.id;
});

const shopsNumber = computed(() => {
  return organisationStore.activityShops.length;
});

const token = computed(() => {
  return configStore.config.token;
});

function delayHidePopover() {
  if (delayPopover) clearTimeout(delayPopover);
  delayPopover = setTimeout(() => {
    selectMenu.value = undefined;
  }, 200);
}

function ckilckOutside(e: Event) {
  if (!e.composedPath().some(el => menuButtons.value.includes(el))) {
    showPopover.value = false;
    delayHidePopover();
  }
}

const displayMenu = (id: string) => {
  if (selectMenu.value === id) {
    showPopover.value = !showPopover.value;
    delayHidePopover();
  } else {
    showPopover.value = true;
    selectMenu.value = id;
  }
};

function updatePopover(value: boolean) {
  showPopover.value = value;
  nextTick(() => {
    showPopover.value = undefined;
    delayHidePopover();
  });
}

watch([options, preselected], () => {
  if (!preselected.value) return;

  options.value.forEach(option => {
    option.options.forEach(cat => {
      if (preselected.value?.includes(cat.value)) {
        option.value = [cat.value];
      }
    });
  });
});

watch(token, (oldToken, newToken) => {
  if (oldToken !== newToken) organisationStore.get(token.value);
});

watch([shopId], async () => {
  if (token.value && shopId.value) categoriesStore.get(token.value, shopId.value, shopsNumber.value);
});
</script>
<style scoped>
.shadow-menu {
  box-shadow:
    0 7px 12px 0 rgb(0 0 0 / 5%),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
