<template>
  <div class="rentool-app">
    <n-dialog-provider :to="dialContainer">
      <slot></slot>
      <naive-provider-content />
    </n-dialog-provider>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, h } from 'vue';
import { useDialog } from 'naive-ui';

// create div with class rentool-app in top of body only if it does not exist
let dialContainer = document.querySelector('.rentool-dialog') as HTMLElement | null;

if (!dialContainer) {
  dialContainer = document.createElement('div');
  dialContainer.className = 'rentool-dialog';
  document.body.insertBefore(dialContainer, document.body.firstChild);
}

// Marquez la méthode du composant naive à la fenêtre pour appeler la fonction window
function registerNaiveTools() {
  window.$openDialog = useDialog();
}

const NaiveProviderContent = defineComponent({
  setup() {
    registerNaiveTools();
  },
  render() {
    return h('div');
  }
});
</script>
