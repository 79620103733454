import type { user_profile_insert_input as UserProfileInsert } from '@rentool/client-api/dist/utils';
import { useMutation } from '@/api';
import { UserProfile } from '..';

export { UserProfileInsert };

export const mutationUser = {
  linkUserToOrganisation: (orgId: string, userProfileId: string) =>
    useMutation({
      insert_organisation_customer_one: {
        __args: { object: { organisation_id: orgId, user_profile_id: userProfileId } },
        id: true
      }
    }),
  add: (data: UserProfileInsert, orgId: string) =>
    useMutation({
      insert_user_profile_one: {
        __args: { object: { ...data, organisation_customers: { data: [{ organisation_id: orgId }] } } },
        first_name: true,
        last_name: true,
        phone: true,
        id: true,
        organisation_customers: {
          id: true
        }
      }
    }),
  edit: (data: UserProfile) =>
    useMutation({
      update_user_profile_by_pk: {
        __args: {
          pk_columns: { id: data.id },
          _set: {
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone
          }
        },
        first_name: true,
        last_name: true,
        phone: true,
        id: true
      }
    })
};
