<template>
  <div>
    <p class="text-lg font-bold mb-7">Mes coordonnées</p>
    <auto-form
      v-model="loginModel"
      :form-value="loginModel"
      :rule="editContactRule"
      @update:fapi="value => (fapi = value)"
    />
    <div class="flex w-full justify-end">
      <n-button :loading="loading" icon-placement="right" size="large" type="primary" @click="onSubmit"
        >Enregistrer</n-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Api } from '@form-create/naive-ui';
import { useUserStore } from '@/stores';
import { registerRules } from '../../UserAccount/formSchemas';

const userStore = useUserStore();
const editContactRule = registerRules.filter(rule => rule.field !== 'password');
const loading = ref(false);

const loginModel = ref({
  email: userStore.user?.user.email,
  firstName: userStore.profile?.first_name,
  lastName: userStore.profile?.last_name,
  phone: userStore.profile?.phone
});
const fapi = ref<Api>();

async function onSubmit() {
  loading.value = true;
  try {
    const form = await fapi.value?.submit();
    await new Promise(resolve => setTimeout(resolve, 800));
    userStore.editProfile({
      first_name: form.firstName,
      last_name: form.lastName,
      phone: form.phone,
      id: userStore.profile?.id
    });
  } catch (error) {
  } finally {
    loading.value = false;
  }
}
</script>
