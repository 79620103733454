<template>
  <div class="flex gap-6" :class="{ 'flex-col': $matches.sm.only, 'min-h-[600px]': !$matches.sm.only }">
    <user-menu v-model="activeKey" :on-disconnect="onDisconnect" :class="{ 'flex-2/6': !$matches.sm.only }" />
    <div :class="{ 'flex-4/6': !$matches.sm.only, 'pb-32': $matches.sm.only }">
      <user-orders v-if="activeKey == 'orders'" />
      <user-informations v-if="activeKey == 'informations'" />
    </div>
    <div v-if="$matches.sm.only" class="w-full flex fixed bottom-0 left-0 bg-white/95">
      <div class="w-full px-6 py-4">
        <n-button class="w-full" type="error" tertiary size="large" @click="onDisconnect">Se déconnecter</n-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { DialogReactive } from 'naive-ui';
import { useUserStore } from '@/stores';
import { UserOrders } from '../UserOrders';
import { UserInformations } from '../UserInformations';
import UserMenu from './UserMenu.vue';
import { UserGlobalMenu } from '.';

const props = defineProps({
  modal: null,
  selectMenu: null
});

const userStore = useUserStore();

const activeKey = ref<string>(props.selectMenu);

async function onDisconnect() {
  await userStore.logout();
  props.modal.destroy();
}
</script>
