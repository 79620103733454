import { QueryResult, client } from '@/api';
import { queryEventStock } from './query';

const { get } = queryEventStock;

export const EventStockActions = {
  get: (productId: string, startDate: Date, endDate: Date): Promise<QueryResult<ReturnType<typeof get>>> => {
    return client().query(get(productId, startDate, endDate));
  }
};
