// create a enum with languages (be, ch, de, es, gb, it, lu, fr, nl, pt) as keys and complete name in french as values
export enum Languages {
  be = 'Belge',
  ch = 'Suisse',
  de = 'Allemand',
  es = 'Espagnol',
  en = 'Anglais',
  it = 'Italien',
  lu = 'Luxembourgeois',
  fr = 'Français',
  nl = 'Néerlandais',
  pt = 'Portugais'
}

// get keys TypeScript enum
export type LanguagesKeys = keyof typeof Languages;
