import { useQuery } from '@/api';

export function queryUserProfile() {
  return useQuery({
    user_profile: {
      id: true,
      first_name: true,
      last_name: true,
      phone: true
    }
  });
}

export function getUserProfileOrg(orgId: string) {
  return useQuery({
    organisation_customer_aggregate: {
      __args: {
        where: {
          organisation_id: { _eq: orgId }
        }
      },
      aggregate: {
        count: true
      }
    }
  });
}
