<template>
  <div class="flex w-full flex-col gap-2 p-4 justify-between text-md">
    <div class="flex gap-8">
      <image-carousel
        class="w-[500px]"
        :medias="shop.medias"
        :alt-text="shop.shop.name"
        :height="400"
        :width="500"
        :mouse-on="mouseOn"
        @mouseenter="() => (mouseOn = true)"
        @mouseleave="() => (mouseOn = false)"
      />
      <div class="flex-1">
        <div class="text-xl mb-2">
          {{ shop.shop.name }}
        </div>

        <div class="text-xs font-bold">
          {{ shop.presentation?.slogan }}
        </div>

        <div class="mt-4 p-2 bg-gray-100 rounded-md leading-5">
          {{ shop.presentation?.description }}
        </div>

        <div class="mt-4">
          <span class="font-bold">Adresse :</span>
          <div class="leading-5">
            {{ shop.shop.name }}<br />
            {{ shop.shop.address }}<br />{{ shop.shop.postalcode }} {{ shop.shop.city }}
          </div>
        </div>

        <div class="mt-4">
          <span class="font-bold">Dates d'ouverture :</span>
          <div>
            Du
            <span v-date="getDates(shop.dates[0].dates).start" format="ddd DD MMMM YYYY" />
            au
            <span v-date="getDates(shop.dates[0].dates).end" format="ddd DD MMMM YYYY" />
          </div>
        </div>
        <div class="mt-4">
          <span class="font-bold">Langues parlées :</span>
          <div class="flex gap-2 mt-1">
            <span
              v-for="lang in shop.shop.languages"
              :key="lang"
              class="text-xs font-bold bg-gray-100 px-2 py-1 rounded-md opacity-90"
            >
              {{ Languages[lang as LanguagesKeys] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-end">
      <div class="flex gap-2">
        <n-button size="large" tertiary @click="$emit('close', true)"> Retour </n-button>
        <n-button size="large" type="primary" @click="$emit('select', shop.id)"> Sélectionner </n-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ActivityShop } from '@/services';
import { Languages, LanguagesKeys } from '@/enums';

defineProps({
  shop: null
});

defineEmits(["close", "select"]);

const mouseOn = ref(false);

function getDates(dates: Date[]) {
  if (!dates) return { start: '', end: '' };

  const [start, end] = dates;
  return {
    start,
    end
  };
}
</script>

<style scoped></style>
