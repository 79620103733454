<template>
  <tr>
    <td>{{ title }}</td>
    <td class="text-right">
      <div v-if="isDiscount && discount" v-money="price" class="text-sm line-through" />
      <div>
        <span v-if="isDiscount && discount" class="text-red-500 font-bold mr-3">-{{ discount }}%</span>
        <span v-money="discountPrice" class="whitespace-nowrap" />
      </div>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  title: null,
  price: null,
  discount: null,
  isDiscount: { type: Boolean }
});

const discountPrice = computed(() => {
  if (props.isDiscount && props.discount) {
    return props.price - (props.price * props.discount) / 100;
  }

  return props.price;
});
</script>
