import { defineStore } from 'pinia';
import { rAuth } from '@rentool/client-api';
import { dayjs } from '@/utils';
import { cartActions } from '@/services';

const { get, add, del, edit } = cartActions;

type CartCart = {
  [key: string]: any;
};
export type Cart = {
  cart: CartCart;
  human: any;
  productId: string;
  dateStart: Date;
  dateEnd: Date;
  shopId: string;
  id: string;
};

interface CartState {
  /** Liste des produits */
  cart: Cart[];
  datesSelection: {
    start: Date | undefined;
    end: Date | undefined;
  };
}

function apiToCart(cart: any): Cart {
  return {
    cart: cart.content.formData,
    human: {
      article: cart.content.productTitle,
      date: cart.start_date,
      price: cart.content.price
    },
    id: cart.id,
    shopId: cart.shop_id,
    dateStart: cart.start_date,
    dateEnd: cart.end_date,
    productId: cart.product_id
  };
}

export const useCartStore = defineStore('cart-store', {
  state: (): CartState => {
    return {
      cart: [],
      datesSelection: {
        start: undefined,
        end: undefined
      }
    };
  },
  actions: {
    async get(orgActId: string) {
      const isAuthenticated = rAuth.isAuthenticated();
      if (isAuthenticated) {
        const { user_cart: userCart } = await get(orgActId);
        const cartToLocal = userCart.map(cart => apiToCart(cart));
        this.cart = cartToLocal;
      } else {
        const cart = localStorage.getItem(orgActId);
        if (cart === null) return;
        const cartToLocal = JSON.parse(cart).filter((c: any) => {
          if (dayjs(c.dateStart).isSameOrBefore(dayjs())) {
            localStorage.removeItem(orgActId);
            return;
          }
          return c;
        });
        this.cart = cartToLocal;
      }
    },
    async add(cart: Cart, orgActId: string) {
      const isAuthenticated = rAuth.isAuthenticated();
      if (isAuthenticated) {
        const { insert_user_cart_one: userCart } = await add({
          org_act_id: orgActId,
          start_date: dayjs(cart.dateStart).utc().format(),
          end_date: dayjs(cart.dateEnd).utc().format(),
          shop_id: cart.shopId,
          product_id: cart.productId,
          content: {
            formData: cart.cart,
            price: cart.human.price,
            productTitle: cart.human.article,
            UserName: cart.cart.username
          }
        });

        if (userCart) this.cart.push(apiToCart(userCart));
      } else {
        this.cart.push(cart);
        localStorage.setItem(orgActId, JSON.stringify(this.cart));
      }
    },
    async update(cart: Cart, orgActId: string) {
      const isAuthenticated = rAuth.isAuthenticated();

      if (isAuthenticated) {
        const { update_user_cart_by_pk: userCart } = await edit(cart.id, {
          start_date: cart.dateStart,
          end_date: cart.dateEnd,
          product_id: cart.productId,
          content: {
            formData: cart.cart,
            price: cart.human.price,
            productTitle: cart.human.article,
            UserName: cart.cart.username
          }
        });

        if (userCart) this.cart = this.cart.map(c => (c.id === userCart.id ? { ...c, ...apiToCart(userCart) } : c));
      } else {
        const index = this.cart.findIndex(c => c.id === cart.id);
        this.cart[index] = cart;
        localStorage.setItem(orgActId, JSON.stringify(this.cart));
      }
    },
    async delete(cartId: string, orgActId: string) {
      const isAuthenticated = rAuth.isAuthenticated();
      if (isAuthenticated) {
        const { delete_user_cart_by_pk: userCart } = await del(cartId);
        if (userCart) this.cart = this.cart.filter(cart => cart.id !== userCart.id);
      } else {
        this.cart = this.cart.filter(cart => cart.id !== cartId);
        localStorage.setItem(orgActId, JSON.stringify(this.cart));
      }
    },
    setDate({ start, end }: { start: Date | undefined; end: Date | undefined }) {
      this.datesSelection = { start, end };
    }
  }
});
