<template>
  <n-config-provider v-if="token" :theme-overrides="themeStore" class="h-full">
    <dialog-provider>
      <div class="text-left">
        <slot />
      </div>
    </dialog-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import { toRefs, watch } from 'vue';
import { DialogProvider } from '@/components';
import { useThemeStore, useConfigStore } from '@/stores';

const props = defineProps({
  token: null,
  primary: null,
  categories: null
});

const { categories, token, primary } = toRefs(props);

const themeStore = useThemeStore();
const configStore = useConfigStore();

function initToken(id: string) {
  configStore.setToken(id);
}

function initColor(id: string) {
  themeStore.setColors({ primary: id });
}

function initCategories(id: any[]) {
  configStore.setCategories(id || []);
}

if (token.value) {
  initToken(token.value);
}
if (primary.value) {
  initColor(primary.value);
}
if (categories.value) {
  initCategories(categories.value);
}

watch(primary, value => {
  if (value) initColor(value);
});

watch(categories, value => {
  if (value) initCategories(value);
});
</script>
<style scoped>
#mainApp {
  width: 100%;
  height: 100%;
  background-color: #fdfdfd;
}
</style>
