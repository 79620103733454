<template>
  <div ref="form" class="w-full">
    <form-create v-model:api="fApi" v-model="value" :option="fOptions" :rule="rules" />
    <div v-if="notComplete" class="flex w-full justify-end pt-4">
      <n-text type="error"
        ><icon-carbon:warning-filled class="mr-1 text-[20px]" />Veuillez renseigner tous les champs obligatoires
        (*)</n-text
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, toRaw, onBeforeUnmount, computed } from 'vue';
import type { Options, Rule, Api } from '@form-create/naive-ui';
import type { FormData } from '@form-create/core';
import { useMatches } from 'vue-responsiveness';

const props = defineProps({
  formValue: { default: undefined },
  rule: null,
  focus: { type: Boolean, default: false },
  notComplete: { type: Boolean, default: false },
  modelValue: null
});
const emit = defineEmits(["update:fapi", "update:loading", "update:value", "update:modelValue"]);

const form = ref();
const rules = ref([...toRaw(props.rule)]);
const fApi = ref<Api>();

const matches = useMatches();

interface OptionsL extends Options {
  loading: (v: boolean) => void;
}

function changeLoading(v: boolean) {
  emit('update:loading', v);
}

const fOptions = computed<OptionsL>(() => ({
  submitBtn: false,
  loading: changeLoading,
  form: {
    labelPlacement: matches.isOnly('sm') ? 'top' : 'left'
  }
}));

const value = computed({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit('update:modelValue', v);
  }
});

onMounted(() => {
  if (fApi.value) {
    fApi.value?.resetFields();
    if (props.formValue) fApi.value?.coverValue(props.formValue);
    emit('update:fapi', fApi.value);
  }

  if (props.focus)
    setTimeout(() => {
      if (form.value.querySelector('input[type=text]')) form.value.querySelector('input[type=text]').focus();
    }, 400);
});

onBeforeUnmount(() => {
  fApi.value?.resetFields();
});
</script>
