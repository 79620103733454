import { defineStore } from 'pinia';
import { tokenActions } from '@/services';
import { activities_type_enum as ActivityType } from '@rentool/client-api/dist/utils/schema';

type Config = {
  token: string;
  categories?: string[];
  orgActId: string;
  init: Boolean;
  type: ActivityType;
};

interface ConfigState {
  config: Config;
}

export const useConfigStore = defineStore('config-store', {
  state: (): ConfigState => {
    return {
      config: {
        token: '',
        orgActId: '',
        init: false,
        type: 'school'
      }
    };
  },
  getters: {
    getToken: state => state.config.token,
    getOrgActId: state => state.config.orgActId
  },

  actions: {
    async setToken(token: string) {
      this.config.token = token;
      if (this.config.init) return;
      this.config.init = true;
      const {
        token: [{ orgActId, organisation_activity: orgAct }]
      } = await tokenActions.get(token);
      this.config.orgActId = orgActId;
      this.config.init = false;
      if (orgAct?.activity.type) this.config.type = orgAct?.activity.type;
    },
    setCategories(categories: string[]) {
      this.config.categories = categories;
    }
  }
});
