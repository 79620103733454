// import { h } from 'vue';
// import wrapper from 'vue3-webcomponent-wrapper';
import { defineCustomElement } from '@padcom/vue3-ce-noshadow';
// import { UserAccount, UserCaddie } from '@/layouts/';
// import App from './App.vue';
import { getToken } from '@/utils';
import { loadComponent } from './loadComponent';

import './styles/main.css';
import 'uno.css';

const token = getToken() || '';

window.customElements.define(
  'rtool-menu',
  defineCustomElement(loadComponent('MainCategories', token), {
    shadowRoot: false
  })
);

window.customElements.define(
  'rtool-products',
  defineCustomElement(loadComponent('MainProducts', token), {
    shadowRoot: false
  })
);

window.customElements.define(
  'rtool-account',
  defineCustomElement(loadComponent('UserAccount', token), {
    shadowRoot: false
  })
);

window.customElements.define(
  'rtool-caddie',
  defineCustomElement(loadComponent('UserCaddie', token), {
    shadowRoot: false
  })
);
