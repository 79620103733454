<template>
  <n-button
    class="w-full justify-between h-full px-3 min-w[140px] transition-none rounded-full"
    :quaternary="!focused"
    icon-placement="right"
    :type="focused ? 'primary' : 'default'"
    :class="{ 'focused-menu-btn': focused }"
  >
    <div class="flex w-full flex-col p-3 items-start">
      <div class="font-bold text-sm mb-1 opacity-90">Dates</div>
      <div :class="{ 'opacity-70': !selectDates.end, 'font-bold': selectDates.end }">
        <template v-if="!selectDates.start">Sélectionner</template>
        <template v-else>
          <span v-date="selectDates.start" format="D" />
          <span> - </span>
          <span v-date="selectDates.end" format="D MMM YYYY" />
        </template>
      </div>
    </div>
  </n-button>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useCartStore } from '@/stores';

defineProps({
  focused: { type: Boolean }
});

const cartStore = useCartStore();

const selectDates = computed(() => {
  return cartStore.datesSelection;
});
</script>
