<template>
  <div class="relative max-w-[400px]">
    <Transition>
      <n-card
        v-if="loadingComp"
        header-style="padding-bottom:0; padding-top:10px"
        class="w-full h-full flex-1 absolute z-10"
      >
        <template #cover>
          <n-skeleton class="h-[200px] w-full rounded-xl" />
        </template>
        <template #header>
          <n-skeleton class="w-3/4" />
          <n-skeleton class="w-1/3 mt-3" />
        </template>
        <div class="flex gap-2 justify-between mt-6">
          <div class="w-2/3">
            <n-skeleton class="w-2/3" />
            <n-skeleton class="w-2/3 mt-2" />
          </div>
          <div class="w-1/4">
            <n-skeleton />
          </div>
        </div>
        <n-skeleton class="w-full mt-4" />
      </n-card>
    </Transition>
    <n-card
      tag="a"
      href="#"
      header-style="padding-bottom:0; padding-top:10px"
      class="border-0 p-0 min-w-[290px] w-full h-full min-h-[250px] flex-1 hover:shadow-2xl transition-all hover:cursor-pointer"
      @click="
        (e: Event) => {
          e.stopPropagation();
          e.preventDefault();
          onCardClick(product);
        }
      "
      @mouseenter="() => (mouseOn = true)"
      @mouseleave="() => (mouseOn = false)"
    >
      <template #cover>
        <image-carousel
          :medias="product.medias"
          :alt-text="product.description.title"
          :mouse-on="mouseOn"
          :image-holder="false"
          :height="230"
          @update:lazy="imageLoad = false"
        >
          <div class="absolute w-full bottom-0 left-0 text-center bg-gradient-to-t from-black/40 from-50% ... pt-8">
            <p class="text-lg px-4 py-1 mb-1 line-height-tight font-bold text-white uppercase">
              {{ product.description.title }}
            </p>
          </div>
        </image-carousel>
      </template>
      <div class="text-black h-full justify-between flex flex-col">
        <p class="m-0 mb-4 pt-4">{{ product.description.slogan }}</p>

        <div class="flex w-full justify-between mt-2">
          <product-attributes
            v-if="useActivityType().type === 'school'"
            :product="product"
            class="text-sm opacity-70"
          />
          <product-categories v-else :product="product" class="text-sm opacity-70" />
          <product-price :price="price" />
        </div>
      </div>
      <template #footer> </template>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, h, onMounted } from 'vue';
import { NSkeleton } from 'naive-ui';
import { ProductDetail } from '@/layouts';
import { ImageCarousel } from '@/components';
import { useActivityType } from '@/composables';
import { openModal } from '@/utils';
import { Product } from '@/services';
import { getProductPrice, getDiscount } from '@/layouts/RProducts/utils';
import { ProductAttributes, ProductCategories, ProductPrice } from '.';

const props = defineProps({
  product: null,
  options: null,
  loadingTime: null,
  daysNumber: null
});

const mouseOn = ref(false);
const imageLoad = ref(true);
const loading = ref(true);

// const selectedOptions = computed(() => {
//   return props.options
//     .filter(option => option.value.length > 0)
//     .map(option => option.id)
//     .flat();
// });

// function displayCategories(categorie: string) {
//   const findCategorie = props.options.find(option => option.options.some(o => o.value === categorie));
//   if (!findCategorie) return true;
//   return !selectedOptions.value.includes(findCategorie?.id);
// }

// const getCategorieName = (id: string) => {
//   const categorie = props.options
//     .find(option => option.options.some(o => o.value === id))
//     ?.options.find(o => o.value === id);
//   if (categorie) return categorie.label;
//   return '';
// };

const discount = computed(() => (props.product.discount ? getDiscount(props.product.discount) : 0));

const price = computed(() =>
  getProductPrice(props.product, discount.value, props.product.price?.variation === 'days' ? props.daysNumber : 0)
);

const size = ref('1400px');

function resize(modalWidth: string) {
  size.value = modalWidth;
}

function onCardClick(product: Product) {
  size.value = '1400px';
  const modal = openModal(
    {
      backIcon: true,
      content: ({ scrollTo }) => h(ProductDetail, { productId: product.id, modal, resize, scrollTo })
    },
    {
      maxWidth: size
    }
  );
}

const loadingComp = computed(() => loading.value || imageLoad.value);

onMounted(() => {
  setTimeout(() => {
    loading.value = false;
  }, props.loadingTime);
});
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
