import { h, render } from 'vue';
import { RMessage } from '@/components';

export type MessageType = 'success' | 'error' | 'info';

let renderMasg = false;

export function openMessage(message: string, type: MessageType, duration = 4000) {
  // check if the message element already exists
  let div = document.getElementById('rentool-message');

  if (!div) {
    div = document.createElement('div');
    div.id = 'rentool-message';
    div.style.position = 'fixed';
    div.style.top = '15px';
    div.style.left = '15px';
    div.style.width = 'calc(100% - 30px)';
    div.style.display = 'flex';
    div.style.justifyContent = 'center';
    div.style.zIndex = '9999';
    document.body.insertBefore(div, document.body.firstChild);
  }

  const el = h(RMessage, { type, message, duration, onClose: () => render(null, div) });

  if (renderMasg) {
    render(null, div);
    renderMasg = false;
  }

  // if el es already rendered, remove it
  render(el, div);
  renderMasg = true;
}
