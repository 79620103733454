import { defineStore } from 'pinia';
import { rAuth, SignInResponse } from '@rentool/client-api';
import { openMessage } from '@/utils';
import { UserProfile, userActions } from '@/services';
import { useCartStore, useConfigStore, Cart, useOrganisationStore } from '@/stores';

type userSession = SignInResponse['session'];

function checkStore() {
  const cartStore = useCartStore();
  const configStore = useConfigStore();

  const { orgActId } = configStore.config;

  const cart = JSON.parse(localStorage.getItem(orgActId) || '[]');
  cartStore.$reset();
  if (cart.length) {
    cart.forEach(async (item: Cart) => {
      await cartStore.add(item, orgActId);
    });
    localStorage.setItem(orgActId, '');
  }
}

interface UserState {
  user: userSession;
  profile?: UserProfile;
  isInit: boolean;
}

export const useUserStore = defineStore('user-store', {
  state: (): UserState => {
    return {
      user: {} as userSession,
      profile: undefined,
      isInit: false
    };
  },
  getters: {
    isLogin: () => rAuth.isAuthenticated()
  },
  actions: {
    async login(data: { email: string; password: string }): Promise<userSession> {
      return new Promise((resolve, error) => {
        const { email, password } = data;
        rAuth.signIn({ email, password }).then(async ({ session, error: loginError }) => {
          if (loginError) {
            error(loginError);
          }

          if (session) {
            this.user = session;
            const { user_profile: userProfile } = await userActions.getProfile();
            if (userProfile.length) this.profile = { ...userProfile[0] };
            checkStore();
            const organisationStore = useOrganisationStore();
            if (organisationStore.organisation.id)
              this.getUserProfileOrg(organisationStore.organisation.id, userProfile[0].id);
          }
          resolve(session);
        });
      });
    },
    async register(data: { email: string; password: string; firstName: string; lastName: string; phone: string }) {
      const { error, session } = await rAuth.signUp({
        email: data.email,
        password: data.password
      });

      if (error) {
        return Promise.reject(error);
      }

      this.user = session;
      const organisationStore = useOrganisationStore();

      const { insert_user_profile_one: userProfile } = await userActions.addProfile(
        {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phone
        },
        organisationStore.organisation.id
      );

      if (userProfile) {
        this.profile = userProfile;
      }

      checkStore();

      return Promise.resolve(session);
    },
    async editProfile(data: UserProfile) {
      const { update_user_profile_by_pk: userProfile } = await userActions.editProfile(data);
      if (userProfile) {
        this.profile = { ...this.profile, ...userProfile };
        openMessage('Les coordonnées de votre compte ont bien été enregistrée !', 'success');
      }
    },
    async changePassword(newPassword: string) {
      const { error } = await rAuth.changePassword({ newPassword });
      if (error) {
        openMessage(error.message, 'error');
      } else {
        openMessage('Votre mot de passe a bien été modifié !', 'success');
      }
    },
    async getUserProfileOrg(orgId: string, userProfileId: string) {
      const { organisation_customer_aggregate: orgCustomer } = await userActions.getUserProfileOrg(orgId);
      if (orgCustomer.aggregate?.count === 0) {
        await userActions.linkUserToOrganisation(orgId, userProfileId);
      }
    },
    async getSession() {
      await rAuth.isAuthenticatedAsync();
      const sessionUser = rAuth.getSession();

      if (sessionUser) {
        this.user = sessionUser;
        const { user_profile: userProfile } = await userActions.getProfile();
        if (userProfile.length) this.profile = { ...userProfile[0] };
        checkStore();
      }
      this.isInit = true;
    },
    async logout() {
      return new Promise((resolve, error) => {
        rAuth.signOut().then(({ error: logoutError }) => {
          if (logoutError) {
            error(logoutError);
          } else {
            this.user = {} as userSession;
            this.profile = undefined;
            resolve(true);
          }
        });
      });
    }
  }
});
