<template>
  <div class="bg-gray-50 p-6 rounded-xl">
    <div>
      <div class="flex flex-col lg:flex-row flex lg:justify-between lg:items-center mb-3">
        <h1 class="text-xl font-bold m-0 p-0">Commande web N°{{ order.orders_orgs[0].number }}</h1>
        <div v-date="order.created_at" class="text-md font-medium"></div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="flex flex-row justify-between">
          <div>
            <p class="text-sm text-gray-500 m-0 p-0">Status</p>
            <p class="text-lg font-bold m-0 p-0">
              {{ i8nStatus(order.orders_orgs[0].status) }}
            </p>
          </div>
          <div>
            <p class="text-sm text-gray-500 m-0 p-0">Total</p>
            <p v-money="order.orders_orgs[0].total_discount" class="text-lg font-bold m-0 p-0"></p>
          </div>
        </div>
        <div class="mt-3">
          <p class="text-sm text-gray-500 mb-2">Détail de la réservation</p>
          <div v-for="(orderDetail, index) in orderDetails" :key="index">
            <div>
              <span class="font-bold mb-1">{{ orderDetail.userName }}</span> :
              <span>{{ orderDetail.product }}</span>
            </div>

            <div>
              <span class="font-bold"
                ><span v-date="orderDetail.startDate" format="dddd d MMMM YYYY" /> à
                <span v-date="orderDetail.startDate" format="HH:mm"
              /></span>
            </div>
            <div><span v-money="orderDetail.totalPrice" class="font-bold" /></div>
            <n-divider v-if="index + 1 < orderDetails.length" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getUsername } from '@/utils';
import { Order } from '@/services';

const props = defineProps({
  order: null
});

function i8nStatus(status: string) {
  switch (status) {
    case 'pending':
      return 'Validée avec paiement en magasin';
    case 'confirmed':
      return 'Confirmée';
    case 'canceled':
      return 'Annulée';
    case 'completed':
      return 'Terminée';
    case 'paid':
      return 'Payée';
    default:
      return status;
  }
}

const orderDetails = computed(() => {
  const { detail } = props.order.orders_orgs[0];

  if (!detail) return [];
  const test = Object.keys(detail).map(key => {
    return {
      userName: getUsername(detail[key][0].userName),
      product: detail[key][0].product.name,
      categories: detail[key][0].categories,
      startDate: detail[key][0].startDate,
      endDate: detail[key][0].endDate,
      totalPrice: detail[key][0].totalDiscount
    };
  });
  return test;
});
</script>
