import { useQuery } from '@/api';

export const queryCart = useQuery({
  user_cart: {
    id: true,
    content: true,
    start_date: true,
    end_date: true,
    product_id: true
  }
});

export function getCart(orgActId: string) {
  return useQuery({
    user_cart: {
      __args: {
        where: {
          org_act_id: {
            _eq: orgActId
          }
        },
        order_by: [
          {
            created_at: 'desc'
          }
        ]
      },
      ...queryCart.user_cart
    }
  });
}
