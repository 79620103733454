import { App } from 'vue';
import { VueResponsiveness } from 'vue-responsiveness';

export function initResponsive(app: App) {
  return VueResponsiveness.install(app, {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
  });
}
