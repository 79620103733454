import { App, Directive } from 'vue';
import { formatCurrency } from '@/utils';
/* eslint no-param-reassign: "error" */

export default function setupFormatCurrency(app: App) {
  const getCurrency: Directive<HTMLElement, any | undefined> = {
    updated(el, binding) {
      if (binding.value === undefined) {
        el.innerText = 'erreur';
      } else if (binding.value !== null) {
        if (binding.value === 0) el.innerText = 'Inclus';
        else el.innerText = formatCurrency(binding.value).toString();
      }
    },
    mounted(el, binding) {
      if (binding.value === undefined) {
        el.innerText = 'erreur';
      } else if (binding.value !== null) {
        if (binding.value === 0) el.innerText = 'Inclus';
        else el.innerText = formatCurrency(binding.value).toString();
      }
    }
  };

  app.directive('money', getCurrency);
}
