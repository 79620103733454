<template>
  <div class="rounded-xl overflow-hidden relative" :style="`height: ${height}px`">
    <transition name="imageholder">
      <div
        v-if="displayHolderImg && imageHolder"
        class="absolute top-0 left-0 w-full h-full bg-gray-200 flex items-center justify-center z-10"
      >
        <icon-carbon:image style="font-size: 40px" class="opacity-70 animate-bounce" />
      </div>
    </transition>
    <n-carousel
      v-if="medias && medias.length > 1"
      ref="carousel"
      :draggable="!lazy"
      :show-arrow="showArrow"
      :show-dots="!lazy"
      :style="`height: ${height}px`"
      class="bg-gray-200"
    >
      <n-image
        v-for="media in medias"
        :key="media.file?.id"
        :preview-disabled="true"
        :object-fit="objectFit"
        :src="imageResize(media.file?.id, width, media.file?.updatedAt) || replaceImg"
        :alt="altText"
        dot-placement="bottom"
        :lazy="lazy"
        class="w-full hover:shadow"
        width="100%"
        :height="height"
        @load="lazy = false"
      />
      <template #arrow>
        <n-button
          size="medium"
          tertiary
          circle
          class="absolute top-1/2 left-2 -translate-y-1/2 carousel-arrow backdrop-blur-md"
          @click="(e: MouseEvent) => slide(e, 'prev')"
        >
          <icon-carbon:chevron-left />
        </n-button>
        <n-button
          size="medium"
          tertiary
          class="absolute top-1/2 right-2 -translate-y-1/2 carousel-arrow ackdrop-blur-md"
          circle
          @click="(e: MouseEvent) => slide(e, 'next')"
        >
          <icon-carbon:chevron-right />
        </n-button>
      </template>
    </n-carousel>
    <div v-else class="relative w-full overflow-hidden">
      <n-image
        :preview-disabled="true"
        :object-fit="objectFit"
        :src="medias && medias.length ? imageResize(medias[0].file?.id, width, medias[0].file?.updatedAt) : replaceImg"
        :alt="altText"
        class="w-full bg-gray-200"
        :height="height"
        width="100%"
        lazy
        @load="
          () => {
            lazy = false;
          }
        "
      />
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { NCarousel } from 'naive-ui';
import { imageResize } from '@/utils';
import { replaceImg } from '@/imgages';
import { Medias } from '@/services';

const props = defineProps({
  imageHolder: { type: Boolean, default: true },
  medias: null,
  altText: null,
  mouseOn: { type: Boolean, default: false },
  height: { default: 200 },
  width: { default: 350 },
  objectFit: { default: 'cover' }
});

const emit = defineEmits(["update:lazy"]);

type TNCarousel = InstanceType<typeof NCarousel>;

const carousel = ref<TNCarousel | null>(null);
const lazy = ref(true);
const displayHolderImg = ref(false);

const showArrow = computed(() => {
  return props.medias && props.medias.length > 1 && props.mouseOn && !lazy.value;
});

let timeoutLazy: NodeJS.Timeout | null = null;

watch(
  () => lazy.value,
  (oldLazy, newLazy) => {
    if (oldLazy !== newLazy) {
      if (timeoutLazy) clearTimeout(timeoutLazy);
      if (newLazy === true) {
        emit('update:lazy', false);
        displayHolderImg.value = false;
      } else {
        emit('update:lazy', true);
        timeoutLazy = setTimeout(() => {
          displayHolderImg.value = true;
        }, 100);
      }
    }
  }
);

function slide(e: MouseEvent, direction: 'prev' | 'next') {
  e.preventDefault();
  e.stopPropagation();
  if (!carousel.value) return;
  const carouselInstance = carousel.value;

  if (direction === 'prev') {
    carouselInstance.prev();
  } else {
    carouselInstance.next();
  }
}
onMounted(() => {
  watch(
    () => props.medias,
    (oldMedias, newMedias) => {
      if (oldMedias[0]?.file?.id !== newMedias[0]?.file?.id) {
        lazy.value = true;
      }
    }
  );
  setTimeout(() => {
    if (lazy.value) displayHolderImg.value = true;
  }, 100);
});
</script>
<style scoped>
.carousel-arrow {
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: white !important;
  }
}

.imageholder-leave-active {
  transition: opacity 0.3s ease;
  position: absolute;
  z-index: 2;
}

.imageholder-leave-to {
  opacity: 0;
}
</style>
@/imgages
