import { defineStore } from 'pinia';
import { categoriesActions, Categories } from '@/services';

interface CategoriesState {
  /** Liste des produits */
  categories: Categories[];
  /** Liste des catégories sélectionnées */
  selected: any[];
}

const { get } = categoriesActions;

export const useCategoriesStore = defineStore('categories-store', {
  state: (): CategoriesState => {
    return {
      categories: [],
      selected: []
    };
  },
  getters: {
    categoriesToOptions(state) {
      return state.categories.map(ct => ({
        label: ct.title.title,
        id: ct.id,
        value: [],
        mode: ct.mode,
        options: ct.categories.map(c => ({ label: c.title.title, value: c.id }))
      }));
    }
  },
  actions: {
    async get(token: string, shopId: string, shopsNumber: number) {
      const { categorie_type: categories } = await get(token, shopId);
      this.categories = categories;

      const selected = [
        {
          id: 'dates',
          label: 'Dates',
          value: [],
          mode: 'dates',
          options: []
        },
        ...this.categoriesToOptions
      ];

      this.selected =
        shopsNumber > 1
          ? [
              {
                id: 'shops',
                label: 'Magasins',
                value: [],
                mode: 'shops',
                options: []
              },
              ...selected
            ]
          : selected;
    }
    // updateSelected(selected: string[]) {
    //   this.selected = selected;
    // },
  }
});
