<template>
  <div>
    <p class="text-lg font-bold mb-7 p-0">Mot de passe</p>
    <div class="mb-4 opacity-80">
      Pour définir un nouveau mot de passe, veuillez le saisir dans le champ ci-dessous.
    </div>
    <auto-form v-model="data" :rule="editContactRule" @update:fapi="value => (fapi = value)" />
    <div class="flex w-full justify-end">
      <n-button :loading="loading" icon-placement="right" size="large" type="primary" @click="onSubmit"
        >Enregistrer</n-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Api } from '@form-create/naive-ui';
import { useUserStore } from '@/stores';
import { registerRules } from '../../UserAccount/formSchemas';

const userStore = useUserStore();
const editContactRule = registerRules.filter(rule => rule.field === 'password');

const fapi = ref<Api>();
const data = ref({});
const loading = ref(false);

async function onSubmit() {
  loading.value = true;
  try {
    const form = await fapi.value?.submit();
    await new Promise(resolve => setTimeout(resolve, 800));
    await userStore.changePassword(form.password);
    fapi.value?.resetFields();
  } catch (error) {
    return;
  } finally {
    loading.value = false;
  }
}
</script>
