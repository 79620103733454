<script lang="ts">
const manual = true
</script>
<template>
  <div class="flex w-full mb-10">
    <div class="flex flex-1 justify-center" :class="$matches.sm.only ? 'p-2' : 'p-8'">
      <div v-if="cartStore.cart.length" class="max-w-2xl flex-1">
        <p class="mb-1 text-3xl" :class="{ 'pt-4': $matches.isOnly('sm') }">Demande de réservation</p>
        <p class="mb-5 mt-1">Vérifiez les détails de votre réservation pour continuer.</p>
        <div class="bg-white rounded-xl p-5 mb-10">
          <div v-for="item in cartStore.cart" :key="item.id">
            <cart-item :item="item" />

            <n-divider />
          </div>
          <div class="flex justify-end font-bold text-lg mt-4">
            Total à payer (TTC): <span v-money="total" class="ml-4" />
          </div>
        </div>

        <div v-if="!user">
          <p class="mb-1 text-xl font-bold">Compte utilisateur</p>
          <p class="mb-5 mt-1">Connectez-vous ou créez un compte pour continuer.</p>

          <user-login-register :modal="{ destroy: () => {} }" />
        </div>
        <div v-else>
          <div v-if="manual">
            <div class="p-4 bg-white rounded-lg shadow opacity-75">
              Nous avons temporairement désactivé le paiement en ligne.<br />Votre réservation reste garantie et vous
              pourrez régler sur place le jour de votre activité.<br />Merci de votre compréhension et à bientôt pour
              une expérience inoubliable en VTT !
            </div>
            <div class="text-gray-500 mt-8 mb-2 text-sm">
              En cliquant sur "Confirmer ma réservartion", j'accepte les
              <n-button tag="a" size="large" text class="underline" href="/condition-utilisation" target="_blank"
                >conditions générales d'utilisation</n-button
              >
              ainsi que les
              <n-button tag="a" size="large" text class="underline" href="/cgv" target="_blank"
                >conditions générales de vente</n-button
              >
              et j'ai pris connaissance de la
              <n-button tag="a" size="large" text class="underline" href="/mentions-legales" target="_blank"
                >politique de confidentialité</n-button
              >..
            </div>

            <manual-validation :close="close" />
          </div>
          <div v-else>
            <p class="mb-1 text-xl font-bold">Payer votre réservation</p>
            <p class="mb-5 mt-1">Veuillez Sélectionner un mode de paiement.</p>
            <stripe-checkout v-if="!loadingPayment" :amount="total">
              <span class="text-gray-500">
                En cliquant sur "Confirmer et payer", j'accepte les
                <n-button size="large" text class="underline">conditions générales d'utilisation</n-button>
                ainsi que les
                <n-button size="large" text class="underline">conditions générales de services</n-button>
                et j'ai pris connaissance de la
                <n-button size="large" text class="underline">politique de confidentialité</n-button>.
              </span>
            </stripe-checkout>
            <div v-else class="w-full h-sm flex items-center justify-center">
              <n-spin />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="max-w-2xl flex-1">
        <p class="mb-6 text-3xl">Demande de réservation</p>
        <div class="p-6 bg-gray-100 rounded-xl">Votre panier est vide !</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import { NSpin } from 'naive-ui';
import { useUserStore, useCartStore, useProductsStore, useConfigStore, useOrganisationStore } from '@/stores';
import { UserLoginRegister } from '@/layouts/RUser/';
import { StripeCheckout } from '../RPayment';
import ManualValidation from '../RPayment/ManualValidation.vue';
import { CartItem } from './components';

defineProps({
  close: { type: Function }
});

const userStore = useUserStore();
const cartStore = useCartStore();
const productsStore = useProductsStore();
const configStore = useConfigStore();
const organisationStore = useOrganisationStore();

const user = computed(() => userStore.user?.user);
const loadingPayment = ref(false);


const total = computed(() => {
  return cartStore.cart.reduce((acc, item) => acc + item.human.price, 0);
});

watch(total, value => {
  if (value > 0) {
    loadingPayment.value = true;
    setTimeout(() => {
      loadingPayment.value = false;
    }, 500);
  }
});
onMounted(() => {
  productsStore.get(configStore.config.token, organisationStore.selectedShop.id);
  cartStore.get(configStore.getOrgActId);
});
</script>
@/layouts/RUser/UserAccount/components
