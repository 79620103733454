import type { Rule } from '@form-create/naive-ui';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { formRules } from '@/utils';
import { Product } from '@/services';

dayjs.extend(weekday);

export function getOptionSelect(article: Product, date: Date, labelWidth: string): Rule[] {
  // get day of week from date
  const weekDay = dayjs(date).weekday();

  return article.child.map(option => {
    if (option.article.child.length > 0) {
      return {
        type: 'select',
        field: option.article.id,
        title: option.article.description.title,
        validate: option.is_required ? formRules.input : undefined,
        props: {
          clearable: true,
          placeholder: 'Choisir une option',
          size: 'large',
          options: option.article.child.map(child => {
            const isDisabled = child.article.condition && !child.article.weekDays[weekDay];
            return {
              label: `${child.article.description.title} ${isDisabled ? '(non dispobile pour cette date)' : ''}`,
              value: child.article.id,
              disabled: isDisabled
              // style: {
              //   color: isDisabled ? 'gray' : '',
              // },
            };
          })
        },
        wrap: {
          labelWidth
        }
      };
    }

    return {
      type: 'switch',
      field: option.article.id,
      title: option.article.description.title,
      value: option.is_checked,
      wrap: {
        labelWidth
      }
    };
  });
}
