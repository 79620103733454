import { Rule } from '@form-create/naive-ui';
import { formRules } from '@/utils';

export const loginRules: Rule[] = [
  {
    type: 'input',
    field: 'email',
    title: 'Email',
    validate: formRules.email,
    props: {
      placeholder: 'Adresse email',
      clearable: true,
      size: 'large',
      inputProps: {
        autocomplete: 'email'
      }
    },
    wrap: {
      labelWidth: '150px'
    }
  },
  {
    type: 'input',
    field: 'password',
    title: 'Mot de passe',
    validate: formRules.input,
    props: {
      placeholder: 'Mot de passe',
      type: 'password',
      clearable: true,
      size: 'large',
      showPasswordOn: 'click',
      inputProps: {
        autocomplete: 'current-password'
      }
    },
    wrap: {
      labelWidth: '150px'
    }
  }
];
