import type { Rule } from '@form-create/naive-ui';
import { formRules } from '@/utils';
import { Product } from '@/services';

type BodyTpl = {
  active: boolean;
  min: number;
  max: number;
  required: boolean;
};

function buildSelectBody(data: BodyTpl, field: string, title: string, unity: string, labelWidth: string) {
  const optionNbs = data.max - data.min + 1;

  return {
    type: 'select',
    field,
    title,
    validate: data.required ? formRules.number : undefined,
    props: {
      clearable: true,
      filterable: false,
      size: 'large',
      placeholder: 'Choisir une option',
      options: new Array(optionNbs).fill(1).map((_nb, index) => ({
        label: `${index + data.min} ${unity}`,
        value: index + data.min
      }))
    },
    wrap: {
      labelWidth
    }
  };
}

export function getBodySelect(article: Product, labelWidth: string): Rule[] {
  // get day of week from date
  const bodyRule = [];

  if (article.bodytpl?.age.active) {
    bodyRule.push(buildSelectBody(article.bodytpl.age as BodyTpl, 'age', 'Age', 'ans', labelWidth));
  }

  if (article.bodytpl?.height.active) {
    bodyRule.push(buildSelectBody(article.bodytpl.height as BodyTpl, 'height', 'Taille', 'cm', labelWidth));
  }

  if (article.bodytpl?.weight.active) {
    bodyRule.push(buildSelectBody(article.bodytpl.weight as BodyTpl, 'weight', 'Poids', 'Kg', labelWidth));
  }

  if (article.bodytpl?.shoeSize.active) {
    bodyRule.push(buildSelectBody(article.bodytpl.shoeSize as BodyTpl, 'shoeSize', 'Pointure', 'EU', labelWidth));
  }

  if (article.bodytpl?.headMeasure.active) {
    bodyRule.push(
      buildSelectBody(article.bodytpl.headMeasure as BodyTpl, 'headMeasure', 'Tour de tête', 'cm', labelWidth)
    );
  }

  return bodyRule;
}
