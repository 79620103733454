<template>
  <div class="flex flex-col">
    <div v-if="startEnd" class="flex items-center gap-2 mb-2">
      <icon-carbon:time /><span
        >De <span v-date="startEnd.start" format="HH:mm" /> à <span v-date="startEnd.end" format="HH:mm"
      /></span>
    </div>
    <template v-if="!timeOnly">
      <div class="flex items-center gap-2 mb-2">
        <icon-carbon:hourglass /><span>{{ duration }}</span>
      </div>
      <div v-if="product.stage_days" class="flex items-center gap-2 mb-2">
        <icon-carbon:calendar /><span>Stage de {{ product.stage_days }} jours</span>
      </div>
      <div v-if="product.stock && product.stock > 1" class="flex items-center gap-2 mb-2">
        <icon-carbon:user-multiple /><span>Groupe de {{ product.stock }} personnes</span>
      </div>
      <div v-if="product.price?.variation === 'quantity'" class="flex items-center gap-2">
        <icon-carbon:user-multiple /><span>De 1 à {{ product.price.data.length }} personnes</span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { dayjs } from '@/utils';
import { Product } from '@/services';

const props = defineProps({
  product: null,
  startDate: null,
  timeOnly: { type: Boolean }
});

const duration = computed(() => {
  return `Durée: ${
    props.product.stage_days ? `${props.product.stage_days} x ` : ''
  } ${props.product.duration?.replace(':', 'h')}`;
});

const startEnd = computed(() => {
  if (!props.startDate) return undefined;
  const [hour, minute] = props.product?.duration ? props.product.duration.split(':') : [0, 0];
  return {
    start: props.startDate,
    end: dayjs(props.startDate).add(Number(hour), 'hour').add(Number(minute), 'minute').toDate()
  };
});
</script>
