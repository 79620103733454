import { Rule } from '@form-create/naive-ui';
import { formRules } from '@/utils';

export const registerRules: Rule[] = [
  {
    type: 'input',
    field: 'firstName',
    title: 'Prénom',
    validate: formRules.input,
    props: {
      placeholder: 'Prénom',
      clearable: true,
      size: 'large'
    },
    wrap: {
      labelWidth: '150px'
    }
  },
  {
    type: 'input',
    field: 'lastName',
    title: 'Nom',
    validate: formRules.input,
    props: {
      placeholder: 'Nom',
      clearable: true,
      size: 'large'
    },
    wrap: {
      labelWidth: '150px'
    }
  },
  {
    type: 'FormPhone',
    field: 'phone',
    title: 'Téléphone portable',
    validate: formRules.phone,
    props: {
      placeholder: 'Téléphone portable',
      clearable: true,
      size: 'large'
    },
    wrap: {
      labelWidth: '150px'
    }
  },
  {
    type: 'input',
    field: 'email',
    title: 'Email',
    validate: formRules.email,
    props: {
      placeholder: 'Adresse email',
      clearable: true,
      size: 'large',
      inputProps: {
        autocomplete: 'email'
      }
    },
    wrap: {
      labelWidth: '150px'
    }
  },
  {
    type: 'input',
    field: 'password',
    title: 'Mot de passe',
    validate: formRules.pwd,
    props: {
      placeholder: 'Mot de passe',
      type: 'password',
      clearable: true,
      size: 'large',
      showPasswordOn: 'click'
    },
    wrap: {
      labelWidth: '150px'
    }
  }
];
