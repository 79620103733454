import { defineStore } from 'pinia';
import { Product, productsActions } from '@/services';

interface ProductsState {
  /** Liste des produits */
  products: Product[];
  init: boolean;
}

const { get, getProduct } = productsActions;

export const useProductsStore = defineStore('products-store', {
  state: (): ProductsState => {
    return {
      products: [],
      init: false
    };
  },
  getters: {
    getProductById:
      state =>
      async (id: string, date: Date, force = false): Promise<Product> => {
        return new Promise((resolve, _error) => {
          const product = state.products.find(p => p.id === id);
          if (product && !force) {
            resolve(product);
          } else {
            getProduct(id, date).then(({ articles_by_pk: article }) => {
              if (article) resolve(article);
            });
          }
        });
      }
  },
  actions: {
    async get(orgActId: string, shopId: string, start?: Date) {
      if (this.init) return;
      this.init = true;
      const { articles } = await get(orgActId, shopId, start);
      this.products = articles;
      this.init = false;
    }
  }
});
