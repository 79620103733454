<template>
  <div class="flex justify-between">
    <div v-if="product" class="flex flex-1">
      <image-carousel
        v-if="!$matches.sm.only"
        class="w-[120px] mr-6"
        :medias="product.medias.filter((_p, ind: number) => ind === 0)"
        :alt-text="product.description.title"
        :height="120"
        :image-holder="false"
      />
      <div class="flex-1">
        <p class="mb-1 text-xl">{{ getUsername(item.cart.username) }}</p>
        <p class="my-1 font-bold">{{ item.human.article }}</p>
        <div class="text-sm mb-3 flex flex-col gap-1">
          <div class="flex items-center gap-2">
            <icon-carbon:calendar />
            <div>
              Date :
              <div v-if="product.stage_days" class="inline-block">
                du <span v-date="item.dateStart" format="dddd DD/MM/YYYY" /> au
                <span v-date="endStage" format="dddd DD/MM/YYYY" />
              </div>
              <span v-else v-date="item.dateStart" format="dddd DD/MM/YYYY" />
            </div>
          </div>
          <product-attributes time-only :product="product" :start-date="dayjs(item.dateStart).toDate()" />
        </div>
        <div class="flex gap-2">
          <n-button size="tiny" @click="() => deleteItem(item.id, orgId)">Supprimer</n-button>
          <n-button size="tiny" @click="() => editItem(item)">Modifier</n-button>
        </div>
      </div>
      <div>
        <span v-money="item.human.price" class="text-lg font-bold whitespace-nowrap"></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ProductAttributes, deleteItem, editItem } from '@/layouts';
import { dayjs, getUsername } from '@/utils';
import { Cart, useProductsStore, useConfigStore } from '@/stores';

const props = defineProps({
  item: null
});

const productsStore = useProductsStore();
const configStore = useConfigStore();

const orgId = computed(() => configStore.config.token);

const product = computed(() => {
  return productsStore.products.find(p => p.id === props.item.productId);
});

const endStage = computed(() => {
  return product.value ? dayjs(props.item.dateStart).add(product.value.stage_days - 1 || 0, 'day') : null;
});
</script>
