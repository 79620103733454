<template>
  <div class="flex p-0 h-full">
    <div class="flex flex-col items-start py-4 bg-white">
      <n-button
        v-for="option in select"
        :key="option.id"
        class="w-full h-auto justify-start rounded-none pl-8 pr-5 py-3 min-w-[170px]"
        quaternary
        :type="modelValue.includes(option.id) ? 'primary' : 'default'"
        @click="() => onSelectOption(option.id)"
        @mouseover="() => (menuSelectId = option.id)"
      >
        <span class="font-bold">{{ option.title.title }}</span>
      </n-button>
    </div>
    <option-select :option="menuSelect" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useCategoriesStore } from '@/stores';
import OptionSelect, { OptionSelectType } from './OptionSelect.vue';

const props = defineProps({
  modelValue: null,
  catetegorieId: null
});

const emit = defineEmits(["update:modelValue", "update:showPopover"]);

const categoriesStore = useCategoriesStore();

const menuSelectId = ref<string>();

const select = computed(() => {
  return (
    categoriesStore.categories
      .find(category => category.id === props.catetegorieId)
      ?.categories.filter(c => c.article_categories_aggregate.aggregate?.count) || []
  );
});

const menuSelect = computed<OptionSelectType>(() => {
  const returnData = select.value?.find(cat => cat.id === menuSelectId.value) || select.value[0];

  return {
    id: returnData.id,
    title: { title: returnData.title.title, description: returnData.title.description },
    medias: returnData.medias
  };
});

function onSelectOption(id: string) {
  emit('update:showPopover', false);
  emit('update:modelValue', [id]);
}
</script>
