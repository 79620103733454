import { formatCurrency } from '@/utils';
import { Product, Discount } from '@/services';

export function getProductPrice(
  product: Product | undefined,
  discount = 0,
  days: number = 0
): { discountPrice: string; discount: number; price: string } {
  let nbDays = days;
  if (product?.halfday) nbDays += 1;

  const initPrice: number = product?.price_manual || product?.price?.data[nbDays] || product?.price?.data[0];
  let price = initPrice;
  if (discount) {
    price -= (price * discount) / 100;
  }
  return {
    discountPrice: formatCurrency(price).toString(),
    discount,
    price: formatCurrency(initPrice).toString()
  };
}

export function getDiscount(discount: Discount): number {
  if (discount.discount_dates && discount?.discount_dates.length) {
    return discount.discount_dates[0]?.discount || 0;
  }

  if (discount.discount) {
    return discount.discount;
  }

  return discount.discount;
}
