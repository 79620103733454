<template>
  <div class="calendar-wrapper w-full h-full">
    <div class="flex justify-between items-center">
      <n-button quaternary circle @click="() => (onAnimation ? {} : prevDate())">
        <icon-carbon:arrow-left style="font-size: 20px" />
      </n-button>
      <div class="flex items-center w-full">
        <div
          v-for="nbm in nbMonth"
          :key="`month${nbm}`"
          class="flex-1 text-center capitalize my-2 text-lg font-bold opacity-80"
        >
          {{ textDate(nbm - 1).value }}
        </div>
      </div>
      <n-button quaternary circle @click="() => (onAnimation ? {} : nexDate())">
        <icon-carbon:arrow-right style="font-size: 20px" />
      </n-button>
    </div>
    <div class="w-full flex flex-col">
      <div class="flex w-full gap-8">
        <div v-for="nbm in nbMonth" :key="nbm" class="flex-1">
          <div class="grid grid-cols-7 list-none m-0 p-0 text-center">
            <p v-for="wday in weekdays" :key="wday" class="day-name text-xs font-bold opacity-70">
              {{ wday }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full h-full relative transition-all overflow-hidden"
        :style="`min-height:240px; height:${height}px;`"
      >
        <transition :name="monthClass">
          <div :key="startDate" class="flex flex-1 gap-8 h-full">
            <div v-for="nbm in nbMonth" :key="nbm" class="flex-1">
              <div
                ref="daysContainer"
                :key="textDate(nbm - 1).value"
                class="grid grid-cols-7 list-none m-0 p-0 text-center"
              >
                <n-el
                  v-for="day in daysInMonth(nbm - 1)"
                  :key="day"
                  tag="p"
                  class="p-0 m-0"
                  :style="style(day, nbm - 1)"
                >
                  <n-button
                    quaternary
                    circle
                    class="h-auto p-0 m-O w-auto transition-none overflow-hidden"
                    :disabled="checkDisabled(day, nbm - 1)"
                    @click="selectDay(day, nbm - 1)"
                    @mouseenter="() => setHoverDay(day, nbm - 1)"
                  >
                    <div class="h-12 w-12 flex justify-center items-center" :style="dayStyle(day, nbm - 1)">
                      {{ day }}
                    </div>
                  </n-button>
                </n-el>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { NEl } from 'naive-ui';
import { useElementSize } from '@vueuse/core';
import { dayjs, isArray } from '@/utils';
import { CalendarProps } from '.';

const modelValue = defineModel<Date | [Date | undefined, Date | undefined] | undefined>();
const props = defineProps({
  startDay: null,
  endDay: null,
  monthNb: null,
  model: null
});

const emit = defineEmits(["date-select"]);

const weekdays = dayjs.weekdaysShort(true);

const nbMonth = computed(() => props.monthNb || 1);
const daysContainer = ref<HTMLElement | null>(null);
const hoverDay = ref();
const monthClass = ref<'monthNext' | 'monthPrev'>('monthNext');
const onAnimation = ref(false);
const dayNow = dayjs();
const modelDates = computed(() => props.model || 'single');

function initStartDay() {
  let initDay;
  if (modelDates.value === 'single') {
    if (modelValue.value) {
      initDay = dayjs(modelValue.value);
    } else {
      initDay = dayjs(props.startDay);
    }
  } else {
    if (isArray(modelValue.value)) {
      initDay = modelValue.value[0] ? dayjs(modelValue.value[0]) : dayNow;
    } else {
      initDay = modelValue.value ? dayjs(modelValue.value) : dayNow;
    }
    if (props.startDay && initDay.isBefore(dayjs(props.startDay))) {
      initDay = dayjs(props.startDay);
    }
  }

  // model.value = props.model === 'single' ? initDay.toDate() : [initDay.toDate(), initDay.toDate()];
  return initDay;
}

const startDate = ref(initStartDay());

function daysInMonth(nb: number) {
  return startDate.value.add(nb, 'M').daysInMonth();
}

const textDate = (nb: number) => computed(() => startDate.value.add(nb, 'M').format('MMMM YYYY'));

function setHoverDay(day: number, nbm: number) {
  hoverDay.value = startDate.value.add(nbm, 'M').set('D', day);
}

function selectDay(day: number, nbm: number) {
  if (modelDates.value === 'single') {
    modelValue.value = startDate.value.add(nbm, 'M').set('D', day).startOf('day').toDate();
    emit('date-select', true);
    return;
  }

  if (isArray(modelValue.value)) {
    if (modelValue.value[1]) {
      modelValue.value = [startDate.value.add(nbm, 'M').set('D', day).startOf('day').toDate(), undefined];
    } else if (modelValue.value[0]) {
      const dateSel = startDate.value.add(nbm, 'M').set('D', day).startOf('day').toDate();

      if (dayjs(modelValue.value[0]).isAfter(dateSel)) {
        modelValue.value = [dateSel, modelValue.value[0]];
      } else {
        modelValue.value = [modelValue.value[0], dateSel];
      }
      emit('date-select', true);
    } else {
      modelValue.value = [startDate.value.add(nbm, 'M').set('D', day).startOf('day').toDate(), undefined];
    }
  }
}

function prevDate() {
  const newDate = dayjs(startDate.value).add(-1, 'month');
  if (props.startDay && newDate.isBefore(dayjs(props.startDay))) return;
  onAnimation.value = true;
  monthClass.value = 'monthPrev';
  startDate.value = newDate;

  setTimeout(() => {
    onAnimation.value = false;
  }, 150);
}

function nexDate() {
  const newDate = dayjs(startDate.value).add(1, 'month');
  if (props.endDay && newDate.isAfter(dayjs(props.endDay))) return;
  onAnimation.value = true;
  monthClass.value = 'monthNext';
  startDate.value = newDate;

  setTimeout(() => {
    onAnimation.value = false;
  }, 150);
}

function checkDisabled(day: number, nbm: number) {
  if (props.startDay && startDate.value.add(nbm, 'M').set('D', day).isBefore(dayjs(props.startDay))) return true;
  if (props.endDay && startDate.value.add(nbm, 'M').set('D', day).isAfter(dayjs(props.endDay))) return true;
  return false;
}

function style(day: number, nbm: number) {
  const returnStyle: { [key: string]: string | number } = {};

  const dayD = startDate.value.add(nbm, 'M').set('D', day);

  if (day === 1) {
    const startDay = startDate.value.add(nbm, 'M').startOf('month').day();
    returnStyle['grid-column-start'] = startDay;
  }

  if (isArray(modelValue.value)) {
    const startD = modelValue.value[0] ? dayjs(modelValue.value[0]) : false;
    const endD = modelValue.value[1] ? dayjs(modelValue.value[1]) : false;

    if (startD && endD && dayD.isSameOrAfter(startD, 'day') && dayD.isSameOrBefore(endD, 'day')) {
      returnStyle.backgroundColor = '#80808021';

      if (dayD.isSame(startD, 'day')) {
        returnStyle.borderRadius = '50% 0 0 50%';
      }
      if (dayD.isSame(endD, 'day')) {
        returnStyle.borderRadius = '0 50% 50% 0';
      }
      return returnStyle;
    }

    if (startD && !endD && dayD.isSameOrAfter(startD, 'day') && dayD.isSameOrBefore(hoverDay.value, 'day')) {
      if (endD && dayD.isAfter(endD, 'day')) return returnStyle;

      returnStyle.backgroundColor = '#80808021';
      return returnStyle;
    }
  }

  return returnStyle;
}

function dayStyle(day: number, nbm: number) {
  const returnStyle: { [key: string]: string } = {};

  const dayD = startDate.value.add(nbm, 'M').set('D', day);

  if (dayNow.isSame(dayD, 'day')) {
    returnStyle.backgroundColor = '#80808021';
  }

  if (isArray(modelValue.value)) {
    const startD = modelValue.value[0] ? dayjs(modelValue.value[0]) : false;
    const endD = modelValue.value[1] ? dayjs(modelValue.value[1]) : false;

    if (startD && startD.isSame(dayD)) {
      returnStyle.backgroundColor = 'var(--primary-color)';
      returnStyle.color = 'white';
      return returnStyle;
    }
    if (endD && endD.isSame(dayD)) {
      returnStyle.backgroundColor = 'var(--primary-color)';
      returnStyle.color = 'white';
      return returnStyle;
    }
  }
  if (dayjs(modelValue.value).isSame(dayD, 'day')) {
    returnStyle.backgroundColor = 'var(--primary-color)';
    returnStyle.color = 'white';
  }

  return returnStyle;
}

const observEleme = computed(() => {
  if (daysContainer.value) {
    return daysContainer.value[0];
  }
  return null;
});

const { height } = useElementSize(observEleme);
</script>

<style>
.hoverSelect:hover {
  background-color: var(--primary-color);
  color: white;
}

.monthNext-enter-active,
.monthPrev-enter-active,
.monthNext-leave-active,
.monthPrev-leave-active {
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  display: flex;
  flex: 1;
  top: 0;
  left: 0;
}

.monthNext-enter-active {
  transform: translateX(calc(50% + 1rem));
  z-index: 2;
}

.monthNext-enter-to {
  transform: translateX(0);
}

.monthNext-leave-active {
  transform: translateX(0);
}

.monthNext-leave-to {
  transform: translateX(calc(-50% - 1rem));
}

.monthPrev-enter-active {
  transform: translateX(calc(-50% - 1rem));
  z-index: 2;
}

.monthPrev-enter-to {
  transform: translateX(0);
}

.monthPrev-leave-active {
  transform: translateX(0);
}

.monthPrev-leave-to {
  transform: translateX(calc(50% + 1rem));
}
</style>
