import { QueryResult, client } from '@/api';
import { queryOrganisation } from './query';

export type Organisation = NonNullable<
  QueryResult<ReturnType<typeof queryOrganisation>>['organisation_activity'][0]
>['organisation'];

export type ActivityShop = NonNullable<
  QueryResult<ReturnType<typeof queryOrganisation>>['organisation_activity'][0]
>['shopOrgActivitiesByParentOrgActivityId'][0];

export type ActivitySettings = NonNullable<
  QueryResult<ReturnType<typeof queryOrganisation>>['organisation_activity'][0]
>['activity_settings'];

export const organisationActions = {
  get: (token: string): Promise<QueryResult<ReturnType<typeof queryOrganisation>>> => {
    return client().query(queryOrganisation(token));
  }
};
