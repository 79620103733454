<template>
  <div v-if="products.length" class="flex flex-wrap gap-6 justify-center">
    <template v-for="(product, index) in products" :key="product.id">
      <product-card
        :loading-time="loadingTime(index)"
        :product="product"
        :options="options"
        class="flex-1"
        :days-number="nbDays"
      />
    </template>
  </div>
  <div v-else class="flex justify-center">
    <n-spin v-if="loading" size="small" />
    <div v-else class="bg-white rounded-xl px-6 py-4 font-bold opacity-70">
      Auncun résultat trouvé selon votre sélection.
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { NSpin } from 'naive-ui';
import { dayjs } from '@/utils';
import { useCategoriesStore, useProductsStore, useConfigStore, useOrganisationStore, useCartStore } from '@/stores';
import { ProductCard } from '.';

const categoriesStore = useCategoriesStore();
const productsStore = useProductsStore();
const configStore = useConfigStore();
const organisationStore = useOrganisationStore();
const cartStore = useCartStore();

const loading = ref(true);
const options = computed(() => categoriesStore.selected);
const token = computed(() => configStore.config.token);
const shopId = computed(() => organisationStore.selectedShop.id);

const nbDays = computed(() => {
  const numbDays = dayjs(cartStore.datesSelection.end).diff(dayjs(cartStore.datesSelection.start), 'days');
  return numbDays >= 0 ? numbDays : 0;
});

const start = computed(() => cartStore.datesSelection.start);

const products = computed(() => {
  return productsStore.products.filter(product => {
    return options.value.every(option => {
      if (option.value.length === 0) return true;
      return option.value.some(value => product.article_categories.some(ac => ac.categorie_id === value));
    });
  });
});

function loadingTime(index: number) {
  return index * 40;
}

watch([shopId, token, options, start], async () => {
  if (token.value && shopId.value) {
    await productsStore.get(token.value, shopId.value, start.value);
    loading.value = false;
  }
});
</script>
