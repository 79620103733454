import { h } from 'vue';
import { useMatches } from 'vue-responsiveness';
import { ProductForm } from '@/layouts';
import { openModal } from '@/utils';
import { Cart, useCartStore } from '@/stores';

export function deleteItem(id: string, orgId: string) {
  const cartStore = useCartStore();
  cartStore.delete(id, orgId);
}

export function editItem(cart: Cart) {
  const matches = useMatches();
  const modal = openModal(
    {
      content: () =>
        h(
          'div',
          { style: matches.isOnly('sm') ? { padding: '10px' } : { padding: '30px' } },
          h(ProductForm, {
            cart,
            productId: cart.productId,
            dateStart: new Date(cart.dateStart),
            close: () => modal.destroy()
          })
        )
    },
    {
      maxWidth: '1100px'
    }
  );
}
