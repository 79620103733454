import { QueryResult, client, MutationResult } from '@/api';
import { queryUserProfile, getUserProfileOrg } from './query';
import { mutationUser, UserProfileInsert } from './mutation';

export type UserProfile = QueryResult<ReturnType<typeof queryUserProfile>>['user_profile'][0];

const { add, edit, linkUserToOrganisation } = mutationUser;

export const userActions = {
  getProfile: (): Promise<QueryResult<ReturnType<typeof queryUserProfile>>> => {
    return client().query(queryUserProfile());
  },
  addProfile: (data: UserProfileInsert, orgId: string): Promise<MutationResult<ReturnType<typeof add>>> => {
    return client().mutation(add(data, orgId));
  },
  editProfile: (data: UserProfile): Promise<MutationResult<ReturnType<typeof edit>>> => {
    return client().mutation(edit(data));
  },
  linkUserToOrganisation: (
    orgId: string,
    userProfileId: string
  ): Promise<MutationResult<ReturnType<typeof linkUserToOrganisation>>> => {
    return client().mutation(linkUserToOrganisation(orgId, userProfileId));
  },
  getUserProfileOrg: (orgId: string): Promise<QueryResult<ReturnType<typeof getUserProfileOrg>>> => {
    return client().query(getUserProfileOrg(orgId));
  }
};
